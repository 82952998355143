module.exports = (function() {

var REPLACE_MESSAGE_TEMPLATE = /\{\{([^\}]+)\}\}/g;

function RAMLError(path, context, type, _messageVariables) {
  var messageVariables = _messageVariables || {};
  var message = context.ERROR_MESSAGES[type];
  Object.defineProperties(this, {
    message: {
      enumerable: true,
      get: function() {
        if (typeof message === 'function') {
          return message(messageVariables, path);
        }

        return message.replace(REPLACE_MESSAGE_TEMPLATE, function(match) {
          return ''+messageVariables[match.slice(2,-2)] || '';
        });
      }
    },
    path: {
      enumerable: true,
      value: path
    },
    type: {
      enumerable: true,
      value: type
    },
    variables: {
      enumerable: true,
      value: messageVariables
    }
  });
}

var DEFAULT_ERROR_MESSAGES = {
  "PROP_IS_MISSING": "Must be defined",
  "TYPE_NOT_OBJECT": "Must be an object",
  "NUMBER_TYPE": "Must be of type `{{type}}`",
  "NUMBER_MIN": "Must be bigger than or equal to {{value}}",
  "TYPE_NOT_INTEGER": "Must be an integer number",
  "ENUM": "Must be one of {{values}}",
  "TYPE_NOT_STRING": "Must be a string",
  "NUMBER_MAX": "Must be smaller than or equal to {{value}}",
  "TYPE_NOT_NUMBER": "Must be a number",
  "STRING_PATTERN": "Must match the pattern `{{pattern}}`",
  "LENGTH_MIN": "Must be at least {{value}} characters long",
  "ITEMS_UNIQUE": "Must contain only unique items",
  "TYPE_NOT_ARRAY": "Must be an array",
  "TYPE_NOT_BOOLEAN": "Must be a boolean value",
  "TYPE_NOT_DATETIME": "Must be a date/time string",
  "ITEMS_MIN": "Must contain at least {{value}} items in the array",
  "ITEMS_MAX": "Must contain at most {{value}} items in the array",
  "LENGTH_MAX": "Must be at most {{value}} characters long"
}

var DEFAULT_CONTEXT = {
	HELPERS: [
		new Float32Array(1),
	],
	ENUMS: [
		["wait_forever","relaunch_after_timeout"],
		["youngest_first","oldest_first"],
		["mesos","docker"],
		["disabled"],
		["bridge","host","none","user"],
		["http","https","tcp","command","mesos_tcp","mesos_http","mesos_https"],
		["ipv4","ipv6"],
		["http","https"],
		["container","container/bridge","host"],
		["tcp","udp","udp,tcp"],
		["task_error","task_failed","task_finished","task_killed","task_killing","task_running","task_staging","task_starting","task_unreachable","task_unknown","task_gone","task_dropped"],
		["private","share_parent"],
		["ro","rw"],
		["root","path","mount"],
		["csi"],
		["single_node_writer","single_node_reader_only","multi_node_reader_only","multi_node_single_writer","multi_node_multi_writer"],
		["block","mount"],
	],
	REGEX: [
		new RegExp('^(\/?((\.\.)|(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9]))?($|\/))+$'),
		new RegExp('^.*$'),
		new RegExp('^(//cmd)|(/?[^/]+(/[^/]+)*)|$'),
		new RegExp('.*'),
		new RegExp('^[a-z0-9]([-a-z0-9_]*[a-z0-9])?$'),
		new RegExp('^[a-z0-9]([-a-z0-9]*[a-z0-9])?$'),
		new RegExp('^(.+)\.(instance-|marathon-)([^\.]+)$'),
		new RegExp('^([^#]+)[#]([^#]+)[#]([^#]+)$'),
	],
}

var RAMLValidator = function(config) {
	if (!config) config = {};
	var context = Object.assign({}, DEFAULT_CONTEXT);
	
	// Override errorMessages through config
	context.ERROR_MESSAGES = Object.assign(
		{},
		DEFAULT_ERROR_MESSAGES,
		config.errorMessages
	)
	
	var PrivateValidators = {
		/**
		 * 3600
		 * 
		 * This is an in-line specialization of IntegerType
		 * with the following constraints:
		 * 
		 * - format: int64
		 * - minimum: 0
		 */
		inlineIntegerType_b6d3b04861cdc2c21823de4e53983492: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (!((value % 1 === 0))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int64"}));
				}
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
			}
			return errors;
		},
	
		/**
		 * "WAIT_FOREVER"
		 * 
		 * This is an in-line specialization of TaskLostBehavior
		 * with the following constraints:
		 * 
		 * - enum: WAIT_FOREVER,RELAUNCH_AFTER_TIMEOUT
		 */
		inlineTaskLostBehavior_42f02b6c8ad50000c5ecfb3dfd33f3ac: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[0].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"wait_forever, relaunch_after_timeout"}));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - format: double
		 * - minimum: 0
		 * - maximum: 1
		 */
		inlineNumberType_ad71b34619243f232a05ae91f25d0513: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
				if (value > 1) {
					errors.push(new RAMLError(path, context, "NUMBER_MAX", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   "public-facing"
		 * ]
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * - items: StringType
		 * - uniqueItems: true
		 */
		inlineStringType_3b1f04aede5a1e865648311c626a62e2: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.StringType(value, path.concat([i]))
					);
				}, errors);
				if ((function() {
					var valuesSoFar = Object.create(null);
					for (var i = 0; i < value.length; ++i) {
						var val = JSON.stringify(value[i]);
						if (val in valuesSoFar) {
							return true;
						}
						valuesSoFar[val] = true;
					}
					return false;
				})()) {
					errors.push(new RAMLError(path, context, "ITEMS_UNIQUE"));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   "some value"
		 * ]
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * - items: StringType
		 */
		inlineStringType_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.StringType(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * 1.15
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - format: double
		 * - minimum: 1
		 */
		inlineNumberType_c481ed52acea3ffa91ed57e7407ff87f: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 1) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of IntegerType
		 * with the following constraints:
		 * 
		 * - format: int32
		 * - minimum: 0
		 */
		inlineIntegerType_1da39b1015745f6788e8467b4b016d08: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * - minLength: 1
		 */
		inlineStringType_8cef7a2a976678a19a8d836c3a53c236: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   [
		 *     "hostname",
		 *     "UNIQUE"
		 *   ]
		 * ]
		 * 
		 * This is an in-line specialization of AppConstraint
		 * with the following constraints:
		 * 
		 * - items: AppConstraint
		 * - uniqueItems: true
		 */
		inlineAppConstraint_3b1f04aede5a1e865648311c626a62e2: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.AppConstraint(value, path.concat([i]))
					);
				}, errors);
				if ((function() {
					var valuesSoFar = Object.create(null);
					for (var i = 0; i < value.length; ++i) {
						var val = JSON.stringify(value[i]);
						if (val in valuesSoFar) {
							return true;
						}
						valuesSoFar[val] = true;
					}
					return false;
				})()) {
					errors.push(new RAMLError(path, context, "ITEMS_UNIQUE"));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - format: double
		 * - minimum: 0.001
		 */
		inlineNumberType_5471becca47d41cedb309da969544f87: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 0.001) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0.001}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   "/ops/audit"
		 * ]
		 * 
		 * This is an in-line specialization of PathId
		 * with the following constraints:
		 * 
		 * - items: PathId
		 * - uniqueItems: true
		 */
		inlinePathId_3b1f04aede5a1e865648311c626a62e2: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.PathId(value, path.concat([i]))
					);
				}, errors);
				if ((function() {
					var valuesSoFar = Object.create(null);
					for (var i = 0; i < value.length; ++i) {
						var val = JSON.stringify(value[i]);
						if (val in valuesSoFar) {
							return true;
						}
						valuesSoFar[val] = true;
					}
					return false;
				})()) {
					errors.push(new RAMLError(path, context, "ITEMS_UNIQUE"));
				}
			}
			return errors;
		},
	
		/**
		 * 0
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - format: double
		 * - minimum: 0
		 */
		inlineNumberType_fa1b78972b7b05a3129cc02bb09d01d2: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
			}
			return errors;
		},
	
		/**
		 * ""
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * - pattern: ^(//cmd)|(/?[^/]+(/[^/]+)*)|$
		 */
		inlineStringType_d0e7c79ff122579a63d440783fa354cf: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[2].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^(//cmd)|(/?[^/]+(/[^/]+)*)|$"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "uri": "http://download.me/file.tgz",
		 *     "extract": true,
		 *     "executable": true,
		 *     "cache": false
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of Artifact
		 * with the following constraints:
		 * 
		 * - items: Artifact
		 */
		inlineArtifact_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Artifact(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "command": {
		 *       "value": "some value"
		 *     },
		 *     "gracePeriodSeconds": 300,
		 *     "ignoreHttp1xx": true,
		 *     "intervalSeconds": 60,
		 *     "maxConsecutiveFailures": 3,
		 *     "path": "/path/to/health",
		 *     "port": 1,
		 *     "portIndex": 1,
		 *     "protocol": "HTTP",
		 *     "ipProtocol": "IPv4",
		 *     "timeoutSeconds": 20,
		 *     "delaySeconds": 15
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of AppHealthCheck
		 * with the following constraints:
		 * 
		 * - uniqueItems: true
		 * - items: AppHealthCheck
		 */
		inlineAppHealthCheck_3b1f04aede5a1e865648311c626a62e2: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				if ((function() {
					var valuesSoFar = Object.create(null);
					for (var i = 0; i < value.length; ++i) {
						var val = JSON.stringify(value[i]);
						if (val in valuesSoFar) {
							return true;
						}
						valuesSoFar[val] = true;
					}
					return false;
				})()) {
					errors.push(new RAMLError(path, context, "ITEMS_UNIQUE"));
				}
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.AppHealthCheck(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "name": "some value",
		 *     "mode": "container",
		 *     "labels": {}
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of Network
		 * with the following constraints:
		 * 
		 * - items: Network
		 */
		inlineNetwork_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Network(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   0
		 * ]
		 * 
		 * This is an in-line specialization of AnyPort
		 * with the following constraints:
		 * 
		 * - items: AnyPort
		 */
		inlineAnyPort_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.AnyPort(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "port": 0,
		 *     "labels": {},
		 *     "name": "some value",
		 *     "protocol": "tcp"
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of PortDefinition
		 * with the following constraints:
		 * 
		 * - items: PortDefinition
		 */
		inlinePortDefinition_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.PortDefinition(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "name": "readinessCheck",
		 *     "protocol": "HTTP",
		 *     "path": "/path/to/health.",
		 *     "portName": "http-api",
		 *     "intervalSeconds": 30,
		 *     "timeoutSeconds": 10,
		 *     "httpStatusCodesForReady": [
		 *       1
		 *     ],
		 *     "preserveLastResponse": false
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of ReadinessCheck
		 * with the following constraints:
		 * 
		 * - items: ReadinessCheck
		 */
		inlineReadinessCheck_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.ReadinessCheck(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * false
		 * 
		 * This is an in-line specialization of BooleanType
		 * with the following constraints:
		 * 
		 * 
		 */
		inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e: function(value, path) {
			var errors = [];
			path = path || [];
			if ((value !== false) && (value !== true)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_BOOLEAN"));
			} else {
			}
			return errors;
		},
	
		/**
		 * [
		 *   "some value"
		 * ]
		 * 
		 * This is an in-line specialization of Uri
		 * with the following constraints:
		 * 
		 * - items: Uri
		 */
		inlineUri_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Uri(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * 
		 */
		inlineStringType_d41d8cd98f00b204e9800998ecf8427e: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of DateTimeType
		 * with the following constraints:
		 * 
		 * 
		 */
		inlineDateTimeType_d41d8cd98f00b204e9800998ecf8427e: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(new Date(value).getTime())) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_DATETIME"));
			} else {
			}
			return errors;
		},
	
		/**
		 * "YOUNGEST_FIRST"
		 * 
		 * This is an in-line specialization of KillSelection
		 * with the following constraints:
		 * 
		 * - enum: YOUNGEST_FIRST,OLDEST_FIRST
		 */
		inlineKillSelection_af9950cc31a21fe24612f6bbfd35c849: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[1].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"youngest_first, oldest_first"}));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of IntegerType
		 * with the following constraints:
		 * 
		 * - format: int32
		 */
		inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "id": "some value"
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of Identifiable
		 * with the following constraints:
		 * 
		 * - items: Identifiable
		 */
		inlineIdentifiable_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Identifiable(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "name": "some value",
		 *     "taskId": "some value",
		 *     "ready": true,
		 *     "lastResponse": {
		 *       "status": 1,
		 *       "contentType": "some value",
		 *       "body": "some value"
		 *     }
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of TaskReadinessCheckResult
		 * with the following constraints:
		 * 
		 * - items: TaskReadinessCheckResult
		 */
		inlineTaskReadinessCheckResult_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.TaskReadinessCheckResult(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "appId": "/ops/audit",
		 *     "healthCheckResults": [
		 *       {
		 *         "alive": true,
		 *         "consecutiveFailures": 1,
		 *         "firstSuccess": "some value",
		 *         "instanceId": "some value",
		 *         "lastSuccess": "some value",
		 *         "lastFailure": "some value",
		 *         "lastFailureCause": "some value"
		 *       }
		 *     ],
		 *     "checkResult": {
		 *       "http": {
		 *         "statusCode": 1
		 *       },
		 *       "tcp": {
		 *         "succeeded": true
		 *       },
		 *       "command": {
		 *         "exitCode": 1
		 *       }
		 *     },
		 *     "host": "some value",
		 *     "id": "some value",
		 *     "ipAddresses": [
		 *       {
		 *         "ipAddress": "some value",
		 *         "protocol": "some value"
		 *       }
		 *     ],
		 *     "ports": [
		 *       1
		 *     ],
		 *     "servicePorts": [
		 *       1
		 *     ],
		 *     "slaveId": "some value",
		 *     "state": "some value",
		 *     "stagedAt": "some value",
		 *     "startedAt": "some value",
		 *     "version": "some value",
		 *     "localVolumes": [
		 *       {
		 *         "runSpecId": "/ops/audit",
		 *         "containerPath": "some value",
		 *         "uuid": "some value",
		 *         "persistenceId": "some value"
		 *       }
		 *     ],
		 *     "region": "some value",
		 *     "zone": "some value",
		 *     "role": "some value"
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of Task
		 * with the following constraints:
		 * 
		 * - items: Task
		 */
		inlineTask_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Task(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * "DOCKER"
		 * 
		 * This is an in-line specialization of EngineType
		 * with the following constraints:
		 * 
		 * - enum: MESOS,DOCKER
		 */
		inlineEngineType_ac83465795b5f6d40807390b3f6c8ef5: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[2].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"mesos, docker"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "containerPath": "some value",
		 *     "persistent": {
		 *       "type": "some value",
		 *       "size": 1,
		 *       "maxSize": 1,
		 *       "profileName": "some value",
		 *       "constraints": [
		 *         [
		 *           "some value"
		 *         ]
		 *       ]
		 *     },
		 *     "mode": "some value"
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of AppVolume
		 * with the following constraints:
		 * 
		 * - items: AppVolume
		 */
		inlineAppVolume_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.AppVolume(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "containerPort": 0,
		 *     "hostPort": 1,
		 *     "labels": {},
		 *     "name": "some value",
		 *     "protocol": "tcp",
		 *     "servicePort": 0,
		 *     "networkNames": [
		 *       "some value"
		 *     ]
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of ContainerPortMapping
		 * with the following constraints:
		 * 
		 * - items: ContainerPortMapping
		 */
		inlineContainerPortMapping_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.ContainerPortMapping(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * 0.2
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 0.1
		 * - format: double
		 */
		inlineNumberType_2198049c676f86df910c1952accf87e1: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 0.1) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0.1}));
				}
			}
			return errors;
		},
	
		/**
		 * 512
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 32
		 * - format: double
		 */
		inlineNumberType_5fc3df80503e193197ced2bef998de82: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 32) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":32}));
				}
			}
			return errors;
		},
	
		/**
		 * 50
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 10
		 * - format: double
		 */
		inlineNumberType_6914d15a748f523af0fc18474685aa42: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 10) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":10}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of DockerNetwork
		 * with the following constraints:
		 * 
		 * - enum: BRIDGE,HOST,NONE,USER
		 */
		inlineDockerNetwork_97392176b9645001a877ed1e9b844cc9: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[4].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"bridge, host, none, user"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "key": "some value",
		 *     "value": "some value"
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of DockerParameter
		 * with the following constraints:
		 * 
		 * - items: DockerParameter
		 */
		inlineDockerParameter_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.DockerParameter(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of AnyPort
		 * with the following constraints:
		 * 
		 * - minimum: 0
		 * - maximum: 65535
		 * - format: int32
		 */
		inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
				if (value > 65535) {
					errors.push(new RAMLError(path, context, "NUMBER_MAX", {"value":65535}));
				}
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
			}
			return errors;
		},
	
		/**
		 * "HTTP"
		 * 
		 * This is an in-line specialization of AppHealthCheckProtocol
		 * with the following constraints:
		 * 
		 * - enum: HTTP,HTTPS,TCP,COMMAND,MESOS_TCP,MESOS_HTTP,MESOS_HTTPS
		 */
		inlineAppHealthCheckProtocol_d48bd5285cf380c9f3754324fc7feed2: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[5].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"http, https, tcp, command, mesos_tcp, mesos_http, mesos_https"}));
				}
			}
			return errors;
		},
	
		/**
		 * "IPv4"
		 * 
		 * This is an in-line specialization of IpProtocol
		 * with the following constraints:
		 * 
		 * - enum: IPv4,IPv6
		 */
		inlineIpProtocol_380b8255e1ce5803ef2a53e316ec076b: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[6].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"ipv4, ipv6"}));
				}
			}
			return errors;
		},
	
		/**
		 * "HTTP"
		 * 
		 * This is an in-line specialization of HttpScheme
		 * with the following constraints:
		 * 
		 * - enum: HTTP,HTTPS
		 */
		inlineHttpScheme_9daecb70f32aeec3a0f8567d3168714f: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[7].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"http, https"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "number": 0,
		 *     "name": "some value",
		 *     "protocol": "tcp",
		 *     "labels": {}
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of IpDiscoveryPort
		 * with the following constraints:
		 * 
		 * - items: IpDiscoveryPort
		 */
		inlineIpDiscoveryPort_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.IpDiscoveryPort(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of NetworkName
		 * with the following constraints:
		 * 
		 * - pattern: ^[a-z0-9]([-a-z0-9_]*[a-z0-9])?$
		 * - maxLength: 63
		 * - minLength: 1
		 */
		inlineNetworkName_d5930fd0fc0461b8008a443d59699e32: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[4].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^[a-z0-9]([-a-z0-9_]*[a-z0-9])?$"}));
				}
				if (value.length > 63) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":63}));
				}
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * "container"
		 * 
		 * This is an in-line specialization of NetworkMode
		 * with the following constraints:
		 * 
		 * - enum: container,container/bridge,host
		 */
		inlineNetworkMode_4cccbb4c3c88d1078bf95c85c1818632: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[8].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"container, container/bridge, host"}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of LegacyName
		 * with the following constraints:
		 * 
		 * - pattern: ^[a-z0-9]([-a-z0-9]*[a-z0-9])?$
		 * - minLength: 1
		 */
		inlineLegacyName_6b57bcbac18a961e5de097e580b2c3d1: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[5].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^[a-z0-9]([-a-z0-9]*[a-z0-9])?$"}));
				}
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * "tcp"
		 * 
		 * This is an in-line specialization of NetworkProtocol
		 * with the following constraints:
		 * 
		 * - enum: tcp,udp,udp,tcp
		 */
		inlineNetworkProtocol_929ca93e4a730214947afb3b3214f365: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[9].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"tcp, udp, udp,tcp"}));
				}
			}
			return errors;
		},
	
		/**
		 * "/path/to/health."
		 * 
		 * This is an in-line specialization of Path
		 * with the following constraints:
		 * 
		 * - minLength: 1
		 * - maxLength: 1024
		 */
		inlinePath_293be7de6c009b1ca492ed5c5b0eafa1: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
				if (value.length > 1024) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":1024}));
				}
			}
			return errors;
		},
	
		/**
		 * 10
		 * 
		 * This is an in-line specialization of IntegerType
		 * with the following constraints:
		 * 
		 * - format: int32
		 * - minimum: 1
		 */
		inlineIntegerType_c93f718de273ed296fcdc5ae52e1ebf3: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
				if (value < 1) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   1
		 * ]
		 * 
		 * This is an in-line specialization of IntegerType
		 * with the following constraints:
		 * 
		 * - items: IntegerType
		 * - uniqueItems: true
		 * - minItems: 1
		 */
		inlineIntegerType_9571dae39de95a138d04ae36ba2376aa: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.IntegerType(value, path.concat([i]))
					);
				}, errors);
				if ((function() {
					var valuesSoFar = Object.create(null);
					for (var i = 0; i < value.length; ++i) {
						var val = JSON.stringify(value[i]);
						if (val in valuesSoFar) {
							return true;
						}
						valuesSoFar[val] = true;
					}
					return false;
				})()) {
					errors.push(new RAMLError(path, context, "ITEMS_UNIQUE"));
				}
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "ITEMS_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of InstanceId
		 * with the following constraints:
		 * 
		 * - pattern: ^(.+)\.(instance-|marathon-)([^\.]+)$
		 */
		inlineInstanceId_1c1fec05deb9479959f10ad40b7f76ab: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[6].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^(.+)\\.(instance-|marathon-)([^\\.]+)$"}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of IPCMode
		 * with the following constraints:
		 * 
		 * - enum: PRIVATE,SHARE_PARENT
		 */
		inlineIPCMode_03e037d41990b538b7d9c54cbc0f5f58: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[11].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"private, share_parent"}));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - format: int32
		 */
		inlineNumberType_d5ab06b03ff21f3a6085461bfd6e5065: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   "some value"
		 * ]
		 * 
		 * This is an in-line specialization of Name
		 * with the following constraints:
		 * 
		 * - items: Name
		 */
		inlineName_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Name(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of ReadMode
		 * with the following constraints:
		 * 
		 * - enum: RO,RW
		 */
		inlineReadMode_ebf93bc60688d31156f7c38ab762ecab: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[12].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"ro, rw"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   [
		 *     "some value"
		 *   ]
		 * ]
		 * 
		 * This is an in-line specialization of VolumeConstraint
		 * with the following constraints:
		 * 
		 * - uniqueItems: true
		 * - items: VolumeConstraint
		 */
		inlineVolumeConstraint_3b1f04aede5a1e865648311c626a62e2: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				if ((function() {
					var valuesSoFar = Object.create(null);
					for (var i = 0; i < value.length; ++i) {
						var val = JSON.stringify(value[i]);
						if (val in valuesSoFar) {
							return true;
						}
						valuesSoFar[val] = true;
					}
					return false;
				})()) {
					errors.push(new RAMLError(path, context, "ITEMS_UNIQUE"));
				}
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.VolumeConstraint(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * - enum: csi
		 */
		inlineStringType_c595ffe8648c9df09d9dcb41c8f7e8d8: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[14].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"csi"}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * - enum: SINGLE_NODE_WRITER,SINGLE_NODE_READER_ONLY,MULTI_NODE_READER_ONLY,MULTI_NODE_SINGLE_WRITER,MULTI_NODE_MULTI_WRITER
		 */
		inlineStringType_d7a22895ae8ee33fd7c2a61469e79c18: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[15].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"single_node_writer, single_node_reader_only, multi_node_reader_only, multi_node_single_writer, multi_node_multi_writer"}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * - enum: block,mount
		 */
		inlineStringType_e29b30fec57d24708b46494da69c169a: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[16].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"block, mount"}));
				}
			}
			return errors;
		},
	
	};
	
	var Validators = {
		/**
		 * {
		 *   "relaunchEscalationTimeoutSeconds": 3600,
		 *   "taskLostBehavior": "WAIT_FOREVER"
		 * }
		 */
		AppResidency: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.relaunchEscalationTimeoutSeconds == null) {
					errors.push(new RAMLError(path.concat(['relaunchEscalationTimeoutSeconds']), context, "PROP_IS_MISSING", {name: 'relaunchEscalationTimeoutSeconds'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.relaunchEscalationTimeoutSeconds, path.concat(['relaunchEscalationTimeoutSeconds'])));
				}
				if (value.taskLostBehavior == null) {
					errors.push(new RAMLError(path.concat(['taskLostBehavior']), context, "PROP_IS_MISSING", {name: 'taskLostBehavior'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineTaskLostBehavior_42f02b6c8ad50000c5ecfb3dfd33f3ac(value.taskLostBehavior, path.concat(['taskLostBehavior'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "maximumOverCapacity": 1,
		 *   "minimumHealthCapacity": 1
		 * }
		 */
		UpgradeStrategy: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.maximumOverCapacity == null) {
					errors.push(new RAMLError(path.concat(['maximumOverCapacity']), context, "PROP_IS_MISSING", {name: 'maximumOverCapacity'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineNumberType_ad71b34619243f232a05ae91f25d0513(value.maximumOverCapacity, path.concat(['maximumOverCapacity'])));
				}
				if (value.minimumHealthCapacity == null) {
					errors.push(new RAMLError(path.concat(['minimumHealthCapacity']), context, "PROP_IS_MISSING", {name: 'minimumHealthCapacity'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineNumberType_ad71b34619243f232a05ae91f25d0513(value.minimumHealthCapacity, path.concat(['minimumHealthCapacity'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "id": "/ops/audit",
		 *   "acceptedResourceRoles": [
		 *     "public-facing"
		 *   ],
		 *   "args": [
		 *     "some value"
		 *   ],
		 *   "backoffFactor": 1.15,
		 *   "backoffSeconds": 1,
		 *   "cmd": "some value",
		 *   "constraints": [
		 *     [
		 *       "hostname",
		 *       "UNIQUE"
		 *     ]
		 *   ],
		 *   "container": {
		 *     "type": "DOCKER",
		 *     "docker": {
		 *       "credential": {
		 *         "principal": "some value",
		 *         "secret": "some value"
		 *       },
		 *       "pullConfig": {
		 *         "secret": "some value"
		 *       },
		 *       "forcePullImage": false,
		 *       "image": "some value",
		 *       "network": "some value",
		 *       "parameters": [
		 *         {
		 *           "key": "some value",
		 *           "value": "some value"
		 *         }
		 *       ],
		 *       "portMappings": [
		 *         {
		 *           "containerPort": 0,
		 *           "hostPort": 1,
		 *           "labels": {},
		 *           "name": "some value",
		 *           "protocol": "tcp",
		 *           "servicePort": 0,
		 *           "networkNames": [
		 *             "some value"
		 *           ]
		 *         }
		 *       ],
		 *       "privileged": false
		 *     },
		 *     "linuxInfo": {
		 *       "seccomp": {
		 *         "profileName": "some value",
		 *         "unconfined": false
		 *       },
		 *       "ipcInfo": {
		 *         "mode": "some value",
		 *         "shmSize": 1
		 *       }
		 *     },
		 *     "volumes": [
		 *       {
		 *         "containerPath": "some value",
		 *         "persistent": {
		 *           "type": "some value",
		 *           "size": 1,
		 *           "maxSize": 1,
		 *           "profileName": "some value",
		 *           "constraints": [
		 *             [
		 *               "some value"
		 *             ]
		 *           ]
		 *         },
		 *         "mode": "some value"
		 *       }
		 *     ],
		 *     "portMappings": [
		 *       {
		 *         "containerPort": 0,
		 *         "hostPort": 1,
		 *         "labels": {},
		 *         "name": "some value",
		 *         "protocol": "tcp",
		 *         "servicePort": 0,
		 *         "networkNames": [
		 *           "some value"
		 *         ]
		 *       }
		 *     ]
		 *   },
		 *   "cpus": 1,
		 *   "dependencies": [
		 *     "/ops/audit"
		 *   ],
		 *   "disk": 0,
		 *   "env": {},
		 *   "executor": "",
		 *   "executorResources": {
		 *     "cpus": 0.2,
		 *     "mem": 512,
		 *     "disk": 50
		 *   },
		 *   "fetch": [
		 *     {
		 *       "uri": "http://download.me/file.tgz",
		 *       "extract": true,
		 *       "executable": true,
		 *       "cache": false
		 *     }
		 *   ],
		 *   "healthChecks": [
		 *     {
		 *       "command": {
		 *         "value": "some value"
		 *       },
		 *       "gracePeriodSeconds": 300,
		 *       "ignoreHttp1xx": true,
		 *       "intervalSeconds": 60,
		 *       "maxConsecutiveFailures": 3,
		 *       "path": "/path/to/health",
		 *       "port": 1,
		 *       "portIndex": 1,
		 *       "protocol": "HTTP",
		 *       "ipProtocol": "IPv4",
		 *       "timeoutSeconds": 20,
		 *       "delaySeconds": 15
		 *     }
		 *   ],
		 *   "check": {
		 *     "http": {
		 *       "portIndex": 1,
		 *       "port": 1,
		 *       "path": "some value",
		 *       "scheme": "HTTP"
		 *     },
		 *     "tcp": {
		 *       "portIndex": 1,
		 *       "port": 1
		 *     },
		 *     "exec": {
		 *       "command": {
		 *         "shell": "some value"
		 *       }
		 *     },
		 *     "intervalSeconds": 60,
		 *     "timeoutSeconds": 20,
		 *     "delaySeconds": 15
		 *   },
		 *   "instances": 1,
		 *   "labels": {},
		 *   "maxLaunchDelaySeconds": 300,
		 *   "mem": 128,
		 *   "gpus": 0,
		 *   "ipAddress": {
		 *     "discovery": {
		 *       "ports": [
		 *         {
		 *           "number": 0,
		 *           "name": "some value",
		 *           "protocol": "tcp",
		 *           "labels": {}
		 *         }
		 *       ]
		 *     },
		 *     "groups": [
		 *       "some value"
		 *     ],
		 *     "labels": {},
		 *     "networkName": "some value"
		 *   },
		 *   "networks": [
		 *     {
		 *       "name": "some value",
		 *       "mode": "container",
		 *       "labels": {}
		 *     }
		 *   ],
		 *   "ports": [
		 *     0
		 *   ],
		 *   "portDefinitions": [
		 *     {
		 *       "port": 0,
		 *       "labels": {},
		 *       "name": "some value",
		 *       "protocol": "tcp"
		 *     }
		 *   ],
		 *   "readinessChecks": [
		 *     {
		 *       "name": "readinessCheck",
		 *       "protocol": "HTTP",
		 *       "path": "/path/to/health.",
		 *       "portName": "http-api",
		 *       "intervalSeconds": 30,
		 *       "timeoutSeconds": 10,
		 *       "httpStatusCodesForReady": [
		 *         1
		 *       ],
		 *       "preserveLastResponse": false
		 *     }
		 *   ],
		 *   "residency": {
		 *     "relaunchEscalationTimeoutSeconds": 3600,
		 *     "taskLostBehavior": "WAIT_FOREVER"
		 *   },
		 *   "resourceLimits": {
		 *     "cpus": "some value",
		 *     "mem": "some value"
		 *   },
		 *   "requirePorts": false,
		 *   "secrets": {},
		 *   "taskKillGracePeriodSeconds": 1,
		 *   "upgradeStrategy": {
		 *     "maximumOverCapacity": 1,
		 *     "minimumHealthCapacity": 1
		 *   },
		 *   "uris": [
		 *     "some value"
		 *   ],
		 *   "user": "some value",
		 *   "version": "some value",
		 *   "versionInfo": {
		 *     "lastScalingAt": "some value",
		 *     "lastConfigChangeAt": "some value"
		 *   },
		 *   "killSelection": "YOUNGEST_FIRST",
		 *   "unreachableStrategy": "disabled",
		 *   "tty": true,
		 *   "role": "some value"
		 * }
		 */
		App: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.id == null) {
					errors.push(new RAMLError(path.concat(['id']), context, "PROP_IS_MISSING", {name: 'id'}));
				} else {
					errors = errors.concat(Validators.PathId(value.id, path.concat(['id'])));
				}
				if (value.acceptedResourceRoles != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_3b1f04aede5a1e865648311c626a62e2(value.acceptedResourceRoles, path.concat(['acceptedResourceRoles'])));
				}
				if (value.args != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_c16b318f4fe8b85b2a89ed06127bb779(value.args, path.concat(['args'])));
				}
				if (value.backoffFactor != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_c481ed52acea3ffa91ed57e7407ff87f(value.backoffFactor, path.concat(['backoffFactor'])));
				}
				if (value.backoffSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.backoffSeconds, path.concat(['backoffSeconds'])));
				}
				if (value.cmd != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.cmd, path.concat(['cmd'])));
				}
				if (value.constraints != null) {
					errors = errors.concat(PrivateValidators.inlineAppConstraint_3b1f04aede5a1e865648311c626a62e2(value.constraints, path.concat(['constraints'])));
				}
				if (value.container != null) {
					errors = errors.concat(Validators.Container(value.container, path.concat(['container'])));
				}
				if (value.cpus != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_5471becca47d41cedb309da969544f87(value.cpus, path.concat(['cpus'])));
				}
				if (value.dependencies != null) {
					errors = errors.concat(PrivateValidators.inlinePathId_3b1f04aede5a1e865648311c626a62e2(value.dependencies, path.concat(['dependencies'])));
				}
				if (value.disk != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_fa1b78972b7b05a3129cc02bb09d01d2(value.disk, path.concat(['disk'])));
				}
				if (value.env != null) {
					errors = errors.concat(Validators.LegacyEnvVars(value.env, path.concat(['env'])));
				}
				if (value.executor != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d0e7c79ff122579a63d440783fa354cf(value.executor, path.concat(['executor'])));
				}
				if (value.executorResources != null) {
					errors = errors.concat(Validators.ExecutorResources(value.executorResources, path.concat(['executorResources'])));
				}
				if (value.fetch != null) {
					errors = errors.concat(PrivateValidators.inlineArtifact_c16b318f4fe8b85b2a89ed06127bb779(value.fetch, path.concat(['fetch'])));
				}
				if (value.healthChecks != null) {
					errors = errors.concat(PrivateValidators.inlineAppHealthCheck_3b1f04aede5a1e865648311c626a62e2(value.healthChecks, path.concat(['healthChecks'])));
				}
				if (value.check != null) {
					errors = errors.concat(Validators.AppCheck(value.check, path.concat(['check'])));
				}
				if (value.instances != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.instances, path.concat(['instances'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
				if (value.maxLaunchDelaySeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.maxLaunchDelaySeconds, path.concat(['maxLaunchDelaySeconds'])));
				}
				if (value.mem != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_fa1b78972b7b05a3129cc02bb09d01d2(value.mem, path.concat(['mem'])));
				}
				if (value.gpus != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.gpus, path.concat(['gpus'])));
				}
				if (value.ipAddress != null) {
					errors = errors.concat(Validators.IpAddress(value.ipAddress, path.concat(['ipAddress'])));
				}
				if (value.networks != null) {
					errors = errors.concat(PrivateValidators.inlineNetwork_c16b318f4fe8b85b2a89ed06127bb779(value.networks, path.concat(['networks'])));
				}
				if (value.ports != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_c16b318f4fe8b85b2a89ed06127bb779(value.ports, path.concat(['ports'])));
				}
				if (value.portDefinitions != null) {
					errors = errors.concat(PrivateValidators.inlinePortDefinition_c16b318f4fe8b85b2a89ed06127bb779(value.portDefinitions, path.concat(['portDefinitions'])));
				}
				if (value.readinessChecks != null) {
					errors = errors.concat(PrivateValidators.inlineReadinessCheck_c16b318f4fe8b85b2a89ed06127bb779(value.readinessChecks, path.concat(['readinessChecks'])));
				}
				if (value.residency != null) {
					errors = errors.concat(Validators.AppResidency(value.residency, path.concat(['residency'])));
				}
				if (value.resourceLimits != null) {
					errors = errors.concat(Validators.ResourceLimits(value.resourceLimits, path.concat(['resourceLimits'])));
				}
				if (value.requirePorts != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.requirePorts, path.concat(['requirePorts'])));
				}
				if (value.secrets != null) {
					errors = errors.concat(Validators.Secrets(value.secrets, path.concat(['secrets'])));
				}
				if (value.taskKillGracePeriodSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.taskKillGracePeriodSeconds, path.concat(['taskKillGracePeriodSeconds'])));
				}
				if (value.upgradeStrategy != null) {
					errors = errors.concat(Validators.UpgradeStrategy(value.upgradeStrategy, path.concat(['upgradeStrategy'])));
				}
				if (value.uris != null) {
					errors = errors.concat(PrivateValidators.inlineUri_c16b318f4fe8b85b2a89ed06127bb779(value.uris, path.concat(['uris'])));
				}
				if (value.user != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.user, path.concat(['user'])));
				}
				if (value.version != null) {
					errors = errors.concat(PrivateValidators.inlineDateTimeType_d41d8cd98f00b204e9800998ecf8427e(value.version, path.concat(['version'])));
				}
				if (value.versionInfo != null) {
					errors = errors.concat(Validators.VersionInfo(value.versionInfo, path.concat(['versionInfo'])));
				}
				if (value.killSelection != null) {
					errors = errors.concat(PrivateValidators.inlineKillSelection_af9950cc31a21fe24612f6bbfd35c849(value.killSelection, path.concat(['killSelection'])));
				}
				if (value.unreachableStrategy != null) {
					errors = errors.concat(Validators.UnreachableStrategy(value.unreachableStrategy, path.concat(['unreachableStrategy'])));
				}
				if (value.tty != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.tty, path.concat(['tty'])));
				}
				if (value.role != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.role, path.concat(['role'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "apps": [
		 *     {
		 *       "tasksStaged": 1,
		 *       "tasksRunning": 1,
		 *       "tasksHealthy": 1,
		 *       "tasksUnhealthy": 1,
		 *       "deployments": [
		 *         {
		 *           "id": "some value"
		 *         }
		 *       ],
		 *       "readinessCheckResults": [
		 *         {
		 *           "name": "some value",
		 *           "taskId": "some value",
		 *           "ready": true,
		 *           "lastResponse": {
		 *             "status": 1,
		 *             "contentType": "some value",
		 *             "body": "some value"
		 *           }
		 *         }
		 *       ],
		 *       "tasks": [
		 *         {
		 *           "appId": "/ops/audit",
		 *           "healthCheckResults": [
		 *             {
		 *               "alive": true,
		 *               "consecutiveFailures": 1,
		 *               "firstSuccess": "some value",
		 *               "instanceId": "some value",
		 *               "lastSuccess": "some value",
		 *               "lastFailure": "some value",
		 *               "lastFailureCause": "some value"
		 *             }
		 *           ],
		 *           "checkResult": {
		 *             "http": {
		 *               "statusCode": 1
		 *             },
		 *             "tcp": {
		 *               "succeeded": true
		 *             },
		 *             "command": {
		 *               "exitCode": 1
		 *             }
		 *           },
		 *           "host": "some value",
		 *           "id": "some value",
		 *           "ipAddresses": [
		 *             {
		 *               "ipAddress": "some value",
		 *               "protocol": "some value"
		 *             }
		 *           ],
		 *           "ports": [
		 *             1
		 *           ],
		 *           "servicePorts": [
		 *             1
		 *           ],
		 *           "slaveId": "some value",
		 *           "state": "some value",
		 *           "stagedAt": "some value",
		 *           "startedAt": "some value",
		 *           "version": "some value",
		 *           "localVolumes": [
		 *             {
		 *               "runSpecId": "/ops/audit",
		 *               "containerPath": "some value",
		 *               "uuid": "some value",
		 *               "persistenceId": "some value"
		 *             }
		 *           ],
		 *           "region": "some value",
		 *           "zone": "some value",
		 *           "role": "some value"
		 *         }
		 *       ],
		 *       "lastTaskFailure": {
		 *         "appId": "/ops/audit",
		 *         "host": "some value",
		 *         "message": "some value",
		 *         "state": "some value",
		 *         "taskId": "some value",
		 *         "timestamp": "some value",
		 *         "version": "some value",
		 *         "slaveId": "some value"
		 *       },
		 *       "tasksStats": {
		 *         "startedAfterLastScaling": {
		 *           "stats": {
		 *             "counts": {
		 *               "staged": 1,
		 *               "running": 1,
		 *               "healthy": 1,
		 *               "unhealthy": 1
		 *             },
		 *             "lifeTime": {
		 *               "averageSeconds": 1,
		 *               "medianSeconds": 1
		 *             }
		 *           }
		 *         },
		 *         "withLatestConfig": {
		 *           "stats": {
		 *             "counts": {
		 *               "staged": 1,
		 *               "running": 1,
		 *               "healthy": 1,
		 *               "unhealthy": 1
		 *             },
		 *             "lifeTime": {
		 *               "averageSeconds": 1,
		 *               "medianSeconds": 1
		 *             }
		 *           }
		 *         },
		 *         "withOutdatedConfig": {
		 *           "stats": {
		 *             "counts": {
		 *               "staged": 1,
		 *               "running": 1,
		 *               "healthy": 1,
		 *               "unhealthy": 1
		 *             },
		 *             "lifeTime": {
		 *               "averageSeconds": 1,
		 *               "medianSeconds": 1
		 *             }
		 *           }
		 *         },
		 *         "totalSummary": {
		 *           "stats": {
		 *             "counts": {
		 *               "staged": 1,
		 *               "running": 1,
		 *               "healthy": 1,
		 *               "unhealthy": 1
		 *             },
		 *             "lifeTime": {
		 *               "averageSeconds": 1,
		 *               "medianSeconds": 1
		 *             }
		 *           }
		 *         }
		 *       },
		 *       "id": "/ops/audit",
		 *       "acceptedResourceRoles": [
		 *         "public-facing"
		 *       ],
		 *       "args": [
		 *         "some value"
		 *       ],
		 *       "backoffFactor": 1.15,
		 *       "backoffSeconds": 1,
		 *       "cmd": "some value",
		 *       "constraints": [
		 *         [
		 *           "hostname",
		 *           "UNIQUE"
		 *         ]
		 *       ],
		 *       "container": {
		 *         "type": "DOCKER",
		 *         "docker": {
		 *           "credential": {
		 *             "principal": "some value",
		 *             "secret": "some value"
		 *           },
		 *           "pullConfig": {
		 *             "secret": "some value"
		 *           },
		 *           "forcePullImage": false,
		 *           "image": "some value",
		 *           "network": "some value",
		 *           "parameters": [
		 *             {
		 *               "key": "some value",
		 *               "value": "some value"
		 *             }
		 *           ],
		 *           "portMappings": [
		 *             {
		 *               "containerPort": 0,
		 *               "hostPort": 1,
		 *               "labels": {},
		 *               "name": "some value",
		 *               "protocol": "tcp",
		 *               "servicePort": 0,
		 *               "networkNames": [
		 *                 "some value"
		 *               ]
		 *             }
		 *           ],
		 *           "privileged": false
		 *         },
		 *         "linuxInfo": {
		 *           "seccomp": {
		 *             "profileName": "some value",
		 *             "unconfined": false
		 *           },
		 *           "ipcInfo": {
		 *             "mode": "some value",
		 *             "shmSize": 1
		 *           }
		 *         },
		 *         "volumes": [
		 *           {
		 *             "containerPath": "some value",
		 *             "persistent": {
		 *               "type": "some value",
		 *               "size": 1,
		 *               "maxSize": 1,
		 *               "profileName": "some value",
		 *               "constraints": [
		 *                 [
		 *                   "some value"
		 *                 ]
		 *               ]
		 *             },
		 *             "mode": "some value"
		 *           }
		 *         ],
		 *         "portMappings": [
		 *           {
		 *             "containerPort": 0,
		 *             "hostPort": 1,
		 *             "labels": {},
		 *             "name": "some value",
		 *             "protocol": "tcp",
		 *             "servicePort": 0,
		 *             "networkNames": [
		 *               "some value"
		 *             ]
		 *           }
		 *         ]
		 *       },
		 *       "cpus": 1,
		 *       "dependencies": [
		 *         "/ops/audit"
		 *       ],
		 *       "disk": 0,
		 *       "env": {},
		 *       "executor": "",
		 *       "executorResources": {
		 *         "cpus": 0.2,
		 *         "mem": 512,
		 *         "disk": 50
		 *       },
		 *       "fetch": [
		 *         {
		 *           "uri": "http://download.me/file.tgz",
		 *           "extract": true,
		 *           "executable": true,
		 *           "cache": false
		 *         }
		 *       ],
		 *       "healthChecks": [
		 *         {
		 *           "command": {
		 *             "value": "some value"
		 *           },
		 *           "gracePeriodSeconds": 300,
		 *           "ignoreHttp1xx": true,
		 *           "intervalSeconds": 60,
		 *           "maxConsecutiveFailures": 3,
		 *           "path": "/path/to/health",
		 *           "port": 1,
		 *           "portIndex": 1,
		 *           "protocol": "HTTP",
		 *           "ipProtocol": "IPv4",
		 *           "timeoutSeconds": 20,
		 *           "delaySeconds": 15
		 *         }
		 *       ],
		 *       "check": {
		 *         "http": {
		 *           "portIndex": 1,
		 *           "port": 1,
		 *           "path": "some value",
		 *           "scheme": "HTTP"
		 *         },
		 *         "tcp": {
		 *           "portIndex": 1,
		 *           "port": 1
		 *         },
		 *         "exec": {
		 *           "command": {
		 *             "shell": "some value"
		 *           }
		 *         },
		 *         "intervalSeconds": 60,
		 *         "timeoutSeconds": 20,
		 *         "delaySeconds": 15
		 *       },
		 *       "instances": 1,
		 *       "labels": {},
		 *       "maxLaunchDelaySeconds": 300,
		 *       "mem": 128,
		 *       "gpus": 0,
		 *       "ipAddress": {
		 *         "discovery": {
		 *           "ports": [
		 *             {
		 *               "number": 0,
		 *               "name": "some value",
		 *               "protocol": "tcp",
		 *               "labels": {}
		 *             }
		 *           ]
		 *         },
		 *         "groups": [
		 *           "some value"
		 *         ],
		 *         "labels": {},
		 *         "networkName": "some value"
		 *       },
		 *       "networks": [
		 *         {
		 *           "name": "some value",
		 *           "mode": "container",
		 *           "labels": {}
		 *         }
		 *       ],
		 *       "ports": [
		 *         0
		 *       ],
		 *       "portDefinitions": [
		 *         {
		 *           "port": 0,
		 *           "labels": {},
		 *           "name": "some value",
		 *           "protocol": "tcp"
		 *         }
		 *       ],
		 *       "readinessChecks": [
		 *         {
		 *           "name": "readinessCheck",
		 *           "protocol": "HTTP",
		 *           "path": "/path/to/health.",
		 *           "portName": "http-api",
		 *           "intervalSeconds": 30,
		 *           "timeoutSeconds": 10,
		 *           "httpStatusCodesForReady": [
		 *             1
		 *           ],
		 *           "preserveLastResponse": false
		 *         }
		 *       ],
		 *       "residency": {
		 *         "relaunchEscalationTimeoutSeconds": 3600,
		 *         "taskLostBehavior": "WAIT_FOREVER"
		 *       },
		 *       "resourceLimits": {
		 *         "cpus": "some value",
		 *         "mem": "some value"
		 *       },
		 *       "requirePorts": false,
		 *       "secrets": {},
		 *       "taskKillGracePeriodSeconds": 1,
		 *       "upgradeStrategy": {
		 *         "maximumOverCapacity": 1,
		 *         "minimumHealthCapacity": 1
		 *       },
		 *       "uris": [
		 *         "some value"
		 *       ],
		 *       "user": "some value",
		 *       "version": "some value",
		 *       "versionInfo": {
		 *         "lastScalingAt": "some value",
		 *         "lastConfigChangeAt": "some value"
		 *       },
		 *       "killSelection": "YOUNGEST_FIRST",
		 *       "unreachableStrategy": "disabled",
		 *       "tty": true,
		 *       "role": "some value"
		 *     }
		 *   ]
		 * }
		 */
		AppList: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.apps == null) {
					errors.push(new RAMLError(path.concat(['apps']), context, "PROP_IS_MISSING", {name: 'apps'}));
				} else {
					errors = errors.concat(Validators.AppInfoAsArray(value.apps, path.concat(['apps'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "versions": [
		 *     "some value"
		 *   ]
		 * }
		 */
		VersionList: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.versions == null) {
					errors.push(new RAMLError(path.concat(['versions']), context, "PROP_IS_MISSING", {name: 'versions'}));
				} else {
					errors = errors.concat(Validators.DateTimeTypeAsArray(value.versions, path.concat(['versions'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "tasksStaged": 1,
		 *   "tasksRunning": 1,
		 *   "tasksHealthy": 1,
		 *   "tasksUnhealthy": 1,
		 *   "deployments": [
		 *     {
		 *       "id": "some value"
		 *     }
		 *   ],
		 *   "readinessCheckResults": [
		 *     {
		 *       "name": "some value",
		 *       "taskId": "some value",
		 *       "ready": true,
		 *       "lastResponse": {
		 *         "status": 1,
		 *         "contentType": "some value",
		 *         "body": "some value"
		 *       }
		 *     }
		 *   ],
		 *   "tasks": [
		 *     {
		 *       "appId": "/ops/audit",
		 *       "healthCheckResults": [
		 *         {
		 *           "alive": true,
		 *           "consecutiveFailures": 1,
		 *           "firstSuccess": "some value",
		 *           "instanceId": "some value",
		 *           "lastSuccess": "some value",
		 *           "lastFailure": "some value",
		 *           "lastFailureCause": "some value"
		 *         }
		 *       ],
		 *       "checkResult": {
		 *         "http": {
		 *           "statusCode": 1
		 *         },
		 *         "tcp": {
		 *           "succeeded": true
		 *         },
		 *         "command": {
		 *           "exitCode": 1
		 *         }
		 *       },
		 *       "host": "some value",
		 *       "id": "some value",
		 *       "ipAddresses": [
		 *         {
		 *           "ipAddress": "some value",
		 *           "protocol": "some value"
		 *         }
		 *       ],
		 *       "ports": [
		 *         1
		 *       ],
		 *       "servicePorts": [
		 *         1
		 *       ],
		 *       "slaveId": "some value",
		 *       "state": "some value",
		 *       "stagedAt": "some value",
		 *       "startedAt": "some value",
		 *       "version": "some value",
		 *       "localVolumes": [
		 *         {
		 *           "runSpecId": "/ops/audit",
		 *           "containerPath": "some value",
		 *           "uuid": "some value",
		 *           "persistenceId": "some value"
		 *         }
		 *       ],
		 *       "region": "some value",
		 *       "zone": "some value",
		 *       "role": "some value"
		 *     }
		 *   ],
		 *   "lastTaskFailure": {
		 *     "appId": "/ops/audit",
		 *     "host": "some value",
		 *     "message": "some value",
		 *     "state": "some value",
		 *     "taskId": "some value",
		 *     "timestamp": "some value",
		 *     "version": "some value",
		 *     "slaveId": "some value"
		 *   },
		 *   "tasksStats": {
		 *     "startedAfterLastScaling": {
		 *       "stats": {
		 *         "counts": {
		 *           "staged": 1,
		 *           "running": 1,
		 *           "healthy": 1,
		 *           "unhealthy": 1
		 *         },
		 *         "lifeTime": {
		 *           "averageSeconds": 1,
		 *           "medianSeconds": 1
		 *         }
		 *       }
		 *     },
		 *     "withLatestConfig": {
		 *       "stats": {
		 *         "counts": {
		 *           "staged": 1,
		 *           "running": 1,
		 *           "healthy": 1,
		 *           "unhealthy": 1
		 *         },
		 *         "lifeTime": {
		 *           "averageSeconds": 1,
		 *           "medianSeconds": 1
		 *         }
		 *       }
		 *     },
		 *     "withOutdatedConfig": {
		 *       "stats": {
		 *         "counts": {
		 *           "staged": 1,
		 *           "running": 1,
		 *           "healthy": 1,
		 *           "unhealthy": 1
		 *         },
		 *         "lifeTime": {
		 *           "averageSeconds": 1,
		 *           "medianSeconds": 1
		 *         }
		 *       }
		 *     },
		 *     "totalSummary": {
		 *       "stats": {
		 *         "counts": {
		 *           "staged": 1,
		 *           "running": 1,
		 *           "healthy": 1,
		 *           "unhealthy": 1
		 *         },
		 *         "lifeTime": {
		 *           "averageSeconds": 1,
		 *           "medianSeconds": 1
		 *         }
		 *       }
		 *     }
		 *   },
		 *   "id": "/ops/audit",
		 *   "acceptedResourceRoles": [
		 *     "public-facing"
		 *   ],
		 *   "args": [
		 *     "some value"
		 *   ],
		 *   "backoffFactor": 1.15,
		 *   "backoffSeconds": 1,
		 *   "cmd": "some value",
		 *   "constraints": [
		 *     [
		 *       "hostname",
		 *       "UNIQUE"
		 *     ]
		 *   ],
		 *   "container": {
		 *     "type": "DOCKER",
		 *     "docker": {
		 *       "credential": {
		 *         "principal": "some value",
		 *         "secret": "some value"
		 *       },
		 *       "pullConfig": {
		 *         "secret": "some value"
		 *       },
		 *       "forcePullImage": false,
		 *       "image": "some value",
		 *       "network": "some value",
		 *       "parameters": [
		 *         {
		 *           "key": "some value",
		 *           "value": "some value"
		 *         }
		 *       ],
		 *       "portMappings": [
		 *         {
		 *           "containerPort": 0,
		 *           "hostPort": 1,
		 *           "labels": {},
		 *           "name": "some value",
		 *           "protocol": "tcp",
		 *           "servicePort": 0,
		 *           "networkNames": [
		 *             "some value"
		 *           ]
		 *         }
		 *       ],
		 *       "privileged": false
		 *     },
		 *     "linuxInfo": {
		 *       "seccomp": {
		 *         "profileName": "some value",
		 *         "unconfined": false
		 *       },
		 *       "ipcInfo": {
		 *         "mode": "some value",
		 *         "shmSize": 1
		 *       }
		 *     },
		 *     "volumes": [
		 *       {
		 *         "containerPath": "some value",
		 *         "persistent": {
		 *           "type": "some value",
		 *           "size": 1,
		 *           "maxSize": 1,
		 *           "profileName": "some value",
		 *           "constraints": [
		 *             [
		 *               "some value"
		 *             ]
		 *           ]
		 *         },
		 *         "mode": "some value"
		 *       }
		 *     ],
		 *     "portMappings": [
		 *       {
		 *         "containerPort": 0,
		 *         "hostPort": 1,
		 *         "labels": {},
		 *         "name": "some value",
		 *         "protocol": "tcp",
		 *         "servicePort": 0,
		 *         "networkNames": [
		 *           "some value"
		 *         ]
		 *       }
		 *     ]
		 *   },
		 *   "cpus": 1,
		 *   "dependencies": [
		 *     "/ops/audit"
		 *   ],
		 *   "disk": 0,
		 *   "env": {},
		 *   "executor": "",
		 *   "executorResources": {
		 *     "cpus": 0.2,
		 *     "mem": 512,
		 *     "disk": 50
		 *   },
		 *   "fetch": [
		 *     {
		 *       "uri": "http://download.me/file.tgz",
		 *       "extract": true,
		 *       "executable": true,
		 *       "cache": false
		 *     }
		 *   ],
		 *   "healthChecks": [
		 *     {
		 *       "command": {
		 *         "value": "some value"
		 *       },
		 *       "gracePeriodSeconds": 300,
		 *       "ignoreHttp1xx": true,
		 *       "intervalSeconds": 60,
		 *       "maxConsecutiveFailures": 3,
		 *       "path": "/path/to/health",
		 *       "port": 1,
		 *       "portIndex": 1,
		 *       "protocol": "HTTP",
		 *       "ipProtocol": "IPv4",
		 *       "timeoutSeconds": 20,
		 *       "delaySeconds": 15
		 *     }
		 *   ],
		 *   "check": {
		 *     "http": {
		 *       "portIndex": 1,
		 *       "port": 1,
		 *       "path": "some value",
		 *       "scheme": "HTTP"
		 *     },
		 *     "tcp": {
		 *       "portIndex": 1,
		 *       "port": 1
		 *     },
		 *     "exec": {
		 *       "command": {
		 *         "shell": "some value"
		 *       }
		 *     },
		 *     "intervalSeconds": 60,
		 *     "timeoutSeconds": 20,
		 *     "delaySeconds": 15
		 *   },
		 *   "instances": 1,
		 *   "labels": {},
		 *   "maxLaunchDelaySeconds": 300,
		 *   "mem": 128,
		 *   "gpus": 0,
		 *   "ipAddress": {
		 *     "discovery": {
		 *       "ports": [
		 *         {
		 *           "number": 0,
		 *           "name": "some value",
		 *           "protocol": "tcp",
		 *           "labels": {}
		 *         }
		 *       ]
		 *     },
		 *     "groups": [
		 *       "some value"
		 *     ],
		 *     "labels": {},
		 *     "networkName": "some value"
		 *   },
		 *   "networks": [
		 *     {
		 *       "name": "some value",
		 *       "mode": "container",
		 *       "labels": {}
		 *     }
		 *   ],
		 *   "ports": [
		 *     0
		 *   ],
		 *   "portDefinitions": [
		 *     {
		 *       "port": 0,
		 *       "labels": {},
		 *       "name": "some value",
		 *       "protocol": "tcp"
		 *     }
		 *   ],
		 *   "readinessChecks": [
		 *     {
		 *       "name": "readinessCheck",
		 *       "protocol": "HTTP",
		 *       "path": "/path/to/health.",
		 *       "portName": "http-api",
		 *       "intervalSeconds": 30,
		 *       "timeoutSeconds": 10,
		 *       "httpStatusCodesForReady": [
		 *         1
		 *       ],
		 *       "preserveLastResponse": false
		 *     }
		 *   ],
		 *   "residency": {
		 *     "relaunchEscalationTimeoutSeconds": 3600,
		 *     "taskLostBehavior": "WAIT_FOREVER"
		 *   },
		 *   "resourceLimits": {
		 *     "cpus": "some value",
		 *     "mem": "some value"
		 *   },
		 *   "requirePorts": false,
		 *   "secrets": {},
		 *   "taskKillGracePeriodSeconds": 1,
		 *   "upgradeStrategy": {
		 *     "maximumOverCapacity": 1,
		 *     "minimumHealthCapacity": 1
		 *   },
		 *   "uris": [
		 *     "some value"
		 *   ],
		 *   "user": "some value",
		 *   "version": "some value",
		 *   "versionInfo": {
		 *     "lastScalingAt": "some value",
		 *     "lastConfigChangeAt": "some value"
		 *   },
		 *   "killSelection": "YOUNGEST_FIRST",
		 *   "unreachableStrategy": "disabled",
		 *   "tty": true,
		 *   "role": "some value"
		 * }
		 */
		AppInfo: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.id == null) {
					errors.push(new RAMLError(path.concat(['id']), context, "PROP_IS_MISSING", {name: 'id'}));
				} else {
					errors = errors.concat(Validators.PathId(value.id, path.concat(['id'])));
				}
				if (value.acceptedResourceRoles != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_3b1f04aede5a1e865648311c626a62e2(value.acceptedResourceRoles, path.concat(['acceptedResourceRoles'])));
				}
				if (value.args != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_c16b318f4fe8b85b2a89ed06127bb779(value.args, path.concat(['args'])));
				}
				if (value.backoffFactor != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_c481ed52acea3ffa91ed57e7407ff87f(value.backoffFactor, path.concat(['backoffFactor'])));
				}
				if (value.backoffSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.backoffSeconds, path.concat(['backoffSeconds'])));
				}
				if (value.cmd != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.cmd, path.concat(['cmd'])));
				}
				if (value.constraints != null) {
					errors = errors.concat(PrivateValidators.inlineAppConstraint_3b1f04aede5a1e865648311c626a62e2(value.constraints, path.concat(['constraints'])));
				}
				if (value.container != null) {
					errors = errors.concat(Validators.Container(value.container, path.concat(['container'])));
				}
				if (value.cpus != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_5471becca47d41cedb309da969544f87(value.cpus, path.concat(['cpus'])));
				}
				if (value.dependencies != null) {
					errors = errors.concat(PrivateValidators.inlinePathId_3b1f04aede5a1e865648311c626a62e2(value.dependencies, path.concat(['dependencies'])));
				}
				if (value.disk != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_fa1b78972b7b05a3129cc02bb09d01d2(value.disk, path.concat(['disk'])));
				}
				if (value.env != null) {
					errors = errors.concat(Validators.LegacyEnvVars(value.env, path.concat(['env'])));
				}
				if (value.executor != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d0e7c79ff122579a63d440783fa354cf(value.executor, path.concat(['executor'])));
				}
				if (value.executorResources != null) {
					errors = errors.concat(Validators.ExecutorResources(value.executorResources, path.concat(['executorResources'])));
				}
				if (value.fetch != null) {
					errors = errors.concat(PrivateValidators.inlineArtifact_c16b318f4fe8b85b2a89ed06127bb779(value.fetch, path.concat(['fetch'])));
				}
				if (value.healthChecks != null) {
					errors = errors.concat(PrivateValidators.inlineAppHealthCheck_3b1f04aede5a1e865648311c626a62e2(value.healthChecks, path.concat(['healthChecks'])));
				}
				if (value.check != null) {
					errors = errors.concat(Validators.AppCheck(value.check, path.concat(['check'])));
				}
				if (value.instances != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.instances, path.concat(['instances'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
				if (value.maxLaunchDelaySeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.maxLaunchDelaySeconds, path.concat(['maxLaunchDelaySeconds'])));
				}
				if (value.mem != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_fa1b78972b7b05a3129cc02bb09d01d2(value.mem, path.concat(['mem'])));
				}
				if (value.gpus != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.gpus, path.concat(['gpus'])));
				}
				if (value.ipAddress != null) {
					errors = errors.concat(Validators.IpAddress(value.ipAddress, path.concat(['ipAddress'])));
				}
				if (value.networks != null) {
					errors = errors.concat(PrivateValidators.inlineNetwork_c16b318f4fe8b85b2a89ed06127bb779(value.networks, path.concat(['networks'])));
				}
				if (value.ports != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_c16b318f4fe8b85b2a89ed06127bb779(value.ports, path.concat(['ports'])));
				}
				if (value.portDefinitions != null) {
					errors = errors.concat(PrivateValidators.inlinePortDefinition_c16b318f4fe8b85b2a89ed06127bb779(value.portDefinitions, path.concat(['portDefinitions'])));
				}
				if (value.readinessChecks != null) {
					errors = errors.concat(PrivateValidators.inlineReadinessCheck_c16b318f4fe8b85b2a89ed06127bb779(value.readinessChecks, path.concat(['readinessChecks'])));
				}
				if (value.residency != null) {
					errors = errors.concat(Validators.AppResidency(value.residency, path.concat(['residency'])));
				}
				if (value.resourceLimits != null) {
					errors = errors.concat(Validators.ResourceLimits(value.resourceLimits, path.concat(['resourceLimits'])));
				}
				if (value.requirePorts != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.requirePorts, path.concat(['requirePorts'])));
				}
				if (value.secrets != null) {
					errors = errors.concat(Validators.Secrets(value.secrets, path.concat(['secrets'])));
				}
				if (value.taskKillGracePeriodSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.taskKillGracePeriodSeconds, path.concat(['taskKillGracePeriodSeconds'])));
				}
				if (value.upgradeStrategy != null) {
					errors = errors.concat(Validators.UpgradeStrategy(value.upgradeStrategy, path.concat(['upgradeStrategy'])));
				}
				if (value.uris != null) {
					errors = errors.concat(PrivateValidators.inlineUri_c16b318f4fe8b85b2a89ed06127bb779(value.uris, path.concat(['uris'])));
				}
				if (value.user != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.user, path.concat(['user'])));
				}
				if (value.version != null) {
					errors = errors.concat(PrivateValidators.inlineDateTimeType_d41d8cd98f00b204e9800998ecf8427e(value.version, path.concat(['version'])));
				}
				if (value.versionInfo != null) {
					errors = errors.concat(Validators.VersionInfo(value.versionInfo, path.concat(['versionInfo'])));
				}
				if (value.killSelection != null) {
					errors = errors.concat(PrivateValidators.inlineKillSelection_af9950cc31a21fe24612f6bbfd35c849(value.killSelection, path.concat(['killSelection'])));
				}
				if (value.unreachableStrategy != null) {
					errors = errors.concat(Validators.UnreachableStrategy(value.unreachableStrategy, path.concat(['unreachableStrategy'])));
				}
				if (value.tty != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.tty, path.concat(['tty'])));
				}
				if (value.role != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.role, path.concat(['role'])));
				}
				if (value.tasksStaged != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.tasksStaged, path.concat(['tasksStaged'])));
				}
				if (value.tasksRunning != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.tasksRunning, path.concat(['tasksRunning'])));
				}
				if (value.tasksHealthy != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.tasksHealthy, path.concat(['tasksHealthy'])));
				}
				if (value.tasksUnhealthy != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.tasksUnhealthy, path.concat(['tasksUnhealthy'])));
				}
				if (value.deployments != null) {
					errors = errors.concat(PrivateValidators.inlineIdentifiable_c16b318f4fe8b85b2a89ed06127bb779(value.deployments, path.concat(['deployments'])));
				}
				if (value.readinessCheckResults != null) {
					errors = errors.concat(PrivateValidators.inlineTaskReadinessCheckResult_c16b318f4fe8b85b2a89ed06127bb779(value.readinessCheckResults, path.concat(['readinessCheckResults'])));
				}
				if (value.tasks != null) {
					errors = errors.concat(PrivateValidators.inlineTask_c16b318f4fe8b85b2a89ed06127bb779(value.tasks, path.concat(['tasks'])));
				}
				if (value.lastTaskFailure != null) {
					errors = errors.concat(Validators.TaskFailure(value.lastTaskFailure, path.concat(['lastTaskFailure'])));
				}
				if (value.tasksStats != null) {
					errors = errors.concat(Validators.TaskStatsByVersion(value.tasksStats, path.concat(['tasksStats'])));
				}
			}
			return errors;
		},
	
		/**
		 * "/ops/audit"
		 */
		PathId: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[0].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^(\\/?((\\.\\.)|(([a-z0-9]|[a-z0-9][a-z0-9\\-]*[a-z0-9])\\.)*([a-z0-9]|[a-z0-9][a-z0-9\\-]*[a-z0-9]))?($|\\/))+$"}));
				}
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "type": "DOCKER",
		 *   "docker": {
		 *     "credential": {
		 *       "principal": "some value",
		 *       "secret": "some value"
		 *     },
		 *     "pullConfig": {
		 *       "secret": "some value"
		 *     },
		 *     "forcePullImage": false,
		 *     "image": "some value",
		 *     "network": "some value",
		 *     "parameters": [
		 *       {
		 *         "key": "some value",
		 *         "value": "some value"
		 *       }
		 *     ],
		 *     "portMappings": [
		 *       {
		 *         "containerPort": 0,
		 *         "hostPort": 1,
		 *         "labels": {},
		 *         "name": "some value",
		 *         "protocol": "tcp",
		 *         "servicePort": 0,
		 *         "networkNames": [
		 *           "some value"
		 *         ]
		 *       }
		 *     ],
		 *     "privileged": false
		 *   },
		 *   "linuxInfo": {
		 *     "seccomp": {
		 *       "profileName": "some value",
		 *       "unconfined": false
		 *     },
		 *     "ipcInfo": {
		 *       "mode": "some value",
		 *       "shmSize": 1
		 *     }
		 *   },
		 *   "volumes": [
		 *     {
		 *       "containerPath": "some value",
		 *       "persistent": {
		 *         "type": "some value",
		 *         "size": 1,
		 *         "maxSize": 1,
		 *         "profileName": "some value",
		 *         "constraints": [
		 *           [
		 *             "some value"
		 *           ]
		 *         ]
		 *       },
		 *       "mode": "some value"
		 *     }
		 *   ],
		 *   "portMappings": [
		 *     {
		 *       "containerPort": 0,
		 *       "hostPort": 1,
		 *       "labels": {},
		 *       "name": "some value",
		 *       "protocol": "tcp",
		 *       "servicePort": 0,
		 *       "networkNames": [
		 *         "some value"
		 *       ]
		 *     }
		 *   ]
		 * }
		 */
		Container: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.type == null) {
					errors.push(new RAMLError(path.concat(['type']), context, "PROP_IS_MISSING", {name: 'type'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineEngineType_ac83465795b5f6d40807390b3f6c8ef5(value.type, path.concat(['type'])));
				}
				if (value.docker != null) {
					errors = errors.concat(Validators.DockerContainer(value.docker, path.concat(['docker'])));
				}
				if (value.linuxInfo != null) {
					errors = errors.concat(Validators.LinuxInfo(value.linuxInfo, path.concat(['linuxInfo'])));
				}
				if (value.volumes != null) {
					errors = errors.concat(PrivateValidators.inlineAppVolume_c16b318f4fe8b85b2a89ed06127bb779(value.volumes, path.concat(['volumes'])));
				}
				if (value.portMappings != null) {
					errors = errors.concat(PrivateValidators.inlineContainerPortMapping_c16b318f4fe8b85b2a89ed06127bb779(value.portMappings, path.concat(['portMappings'])));
				}
			}
			return errors;
		},
	
		/**
		 * {}
		 */
		LegacyEnvVars: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				var matched = [];
				var props = Object.keys(value);
				var regexProps = props;
				matched = regexProps.filter(function(key) {
					return context.REGEX[1].exec(key);
				});
				errors = matched.reduce(function(errors, property) {
					return errors.concat(Validators.EnvVarValueOrSecret(value[property], path.concat([property])));
				}, errors);
			}
			return errors;
		},
	
		/**
		 * {
		 *   "cpus": 0.2,
		 *   "mem": 512,
		 *   "disk": 50
		 * }
		 */
		ExecutorResources: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.cpus != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_2198049c676f86df910c1952accf87e1(value.cpus, path.concat(['cpus'])));
				}
				if (value.mem != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_5fc3df80503e193197ced2bef998de82(value.mem, path.concat(['mem'])));
				}
				if (value.disk != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_6914d15a748f523af0fc18474685aa42(value.disk, path.concat(['disk'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "http": {
		 *     "portIndex": 1,
		 *     "port": 1,
		 *     "path": "some value",
		 *     "scheme": "HTTP"
		 *   },
		 *   "tcp": {
		 *     "portIndex": 1,
		 *     "port": 1
		 *   },
		 *   "exec": {
		 *     "command": {
		 *       "shell": "some value"
		 *     }
		 *   },
		 *   "intervalSeconds": 60,
		 *   "timeoutSeconds": 20,
		 *   "delaySeconds": 15
		 * }
		 */
		AppCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.http != null) {
					errors = errors.concat(Validators.AppHttpCheck(value.http, path.concat(['http'])));
				}
				if (value.tcp != null) {
					errors = errors.concat(Validators.AppTcpCheck(value.tcp, path.concat(['tcp'])));
				}
				if (value.exec != null) {
					errors = errors.concat(Validators.CommandCheck(value.exec, path.concat(['exec'])));
				}
				if (value.intervalSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.intervalSeconds, path.concat(['intervalSeconds'])));
				}
				if (value.timeoutSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.timeoutSeconds, path.concat(['timeoutSeconds'])));
				}
				if (value.delaySeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.delaySeconds, path.concat(['delaySeconds'])));
				}
			}
			return errors;
		},
	
		/**
		 * {}
		 */
		KVLabels: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				var matched = [];
				var props = Object.keys(value);
				var regexProps = props;
				matched = regexProps.filter(function(key) {
					return context.REGEX[3].exec(key);
				});
				errors = matched.reduce(function(errors, property) {
					return errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value[property], path.concat([property])));
				}, errors);
			}
			return errors;
		},
	
		/**
		 * {
		 *   "discovery": {
		 *     "ports": [
		 *       {
		 *         "number": 0,
		 *         "name": "some value",
		 *         "protocol": "tcp",
		 *         "labels": {}
		 *       }
		 *     ]
		 *   },
		 *   "groups": [
		 *     "some value"
		 *   ],
		 *   "labels": {},
		 *   "networkName": "some value"
		 * }
		 */
		IpAddress: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.discovery != null) {
					errors = errors.concat(Validators.IpDiscovery(value.discovery, path.concat(['discovery'])));
				}
				if (value.groups != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_3b1f04aede5a1e865648311c626a62e2(value.groups, path.concat(['groups'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
				if (value.networkName != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.networkName, path.concat(['networkName'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "cpus": "some value",
		 *   "mem": "some value"
		 * }
		 */
		ResourceLimits: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.cpus != null) {
					errors = errors.concat(Validators.ResourceLimit(value.cpus, path.concat(['cpus'])));
				}
				if (value.mem != null) {
					errors = errors.concat(Validators.ResourceLimit(value.mem, path.concat(['mem'])));
				}
			}
			return errors;
		},
	
		/**
		 * {}
		 */
		Secrets: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				var matched = [];
				var props = Object.keys(value);
				var regexProps = props;
				matched = regexProps.filter(function(key) {
					return context.REGEX[3].exec(key);
				});
				errors = matched.reduce(function(errors, property) {
					return errors.concat(Validators.SecretDef(value[property], path.concat([property])));
				}, errors);
			}
			return errors;
		},
	
		/**
		 * {
		 *   "lastScalingAt": "some value",
		 *   "lastConfigChangeAt": "some value"
		 * }
		 */
		VersionInfo: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.lastScalingAt == null) {
					errors.push(new RAMLError(path.concat(['lastScalingAt']), context, "PROP_IS_MISSING", {name: 'lastScalingAt'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineDateTimeType_d41d8cd98f00b204e9800998ecf8427e(value.lastScalingAt, path.concat(['lastScalingAt'])));
				}
				if (value.lastConfigChangeAt == null) {
					errors.push(new RAMLError(path.concat(['lastConfigChangeAt']), context, "PROP_IS_MISSING", {name: 'lastConfigChangeAt'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineDateTimeType_d41d8cd98f00b204e9800998ecf8427e(value.lastConfigChangeAt, path.concat(['lastConfigChangeAt'])));
				}
			}
			return errors;
		},
	
		/**
		 * "disabled"
		 */
		UnreachableStrategy: function(value, path) {
			var errors = [];
			path = path || [];
			errors = errors.concat([
				Validators.UnreachableDisabled(value, path),
				Validators.UnreachableEnabled(value, path),
			].sort(function(a, b) {
				return a.length - b.length;
			})[0]);
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "tasksStaged": 1,
		 *     "tasksRunning": 1,
		 *     "tasksHealthy": 1,
		 *     "tasksUnhealthy": 1,
		 *     "deployments": [
		 *       {
		 *         "id": "some value"
		 *       }
		 *     ],
		 *     "readinessCheckResults": [
		 *       {
		 *         "name": "some value",
		 *         "taskId": "some value",
		 *         "ready": true,
		 *         "lastResponse": {
		 *           "status": 1,
		 *           "contentType": "some value",
		 *           "body": "some value"
		 *         }
		 *       }
		 *     ],
		 *     "tasks": [
		 *       {
		 *         "appId": "/ops/audit",
		 *         "healthCheckResults": [
		 *           {
		 *             "alive": true,
		 *             "consecutiveFailures": 1,
		 *             "firstSuccess": "some value",
		 *             "instanceId": "some value",
		 *             "lastSuccess": "some value",
		 *             "lastFailure": "some value",
		 *             "lastFailureCause": "some value"
		 *           }
		 *         ],
		 *         "checkResult": {
		 *           "http": {
		 *             "statusCode": 1
		 *           },
		 *           "tcp": {
		 *             "succeeded": true
		 *           },
		 *           "command": {
		 *             "exitCode": 1
		 *           }
		 *         },
		 *         "host": "some value",
		 *         "id": "some value",
		 *         "ipAddresses": [
		 *           {
		 *             "ipAddress": "some value",
		 *             "protocol": "some value"
		 *           }
		 *         ],
		 *         "ports": [
		 *           1
		 *         ],
		 *         "servicePorts": [
		 *           1
		 *         ],
		 *         "slaveId": "some value",
		 *         "state": "some value",
		 *         "stagedAt": "some value",
		 *         "startedAt": "some value",
		 *         "version": "some value",
		 *         "localVolumes": [
		 *           {
		 *             "runSpecId": "/ops/audit",
		 *             "containerPath": "some value",
		 *             "uuid": "some value",
		 *             "persistenceId": "some value"
		 *           }
		 *         ],
		 *         "region": "some value",
		 *         "zone": "some value",
		 *         "role": "some value"
		 *       }
		 *     ],
		 *     "lastTaskFailure": {
		 *       "appId": "/ops/audit",
		 *       "host": "some value",
		 *       "message": "some value",
		 *       "state": "some value",
		 *       "taskId": "some value",
		 *       "timestamp": "some value",
		 *       "version": "some value",
		 *       "slaveId": "some value"
		 *     },
		 *     "tasksStats": {
		 *       "startedAfterLastScaling": {
		 *         "stats": {
		 *           "counts": {
		 *             "staged": 1,
		 *             "running": 1,
		 *             "healthy": 1,
		 *             "unhealthy": 1
		 *           },
		 *           "lifeTime": {
		 *             "averageSeconds": 1,
		 *             "medianSeconds": 1
		 *           }
		 *         }
		 *       },
		 *       "withLatestConfig": {
		 *         "stats": {
		 *           "counts": {
		 *             "staged": 1,
		 *             "running": 1,
		 *             "healthy": 1,
		 *             "unhealthy": 1
		 *           },
		 *           "lifeTime": {
		 *             "averageSeconds": 1,
		 *             "medianSeconds": 1
		 *           }
		 *         }
		 *       },
		 *       "withOutdatedConfig": {
		 *         "stats": {
		 *           "counts": {
		 *             "staged": 1,
		 *             "running": 1,
		 *             "healthy": 1,
		 *             "unhealthy": 1
		 *           },
		 *           "lifeTime": {
		 *             "averageSeconds": 1,
		 *             "medianSeconds": 1
		 *           }
		 *         }
		 *       },
		 *       "totalSummary": {
		 *         "stats": {
		 *           "counts": {
		 *             "staged": 1,
		 *             "running": 1,
		 *             "healthy": 1,
		 *             "unhealthy": 1
		 *           },
		 *           "lifeTime": {
		 *             "averageSeconds": 1,
		 *             "medianSeconds": 1
		 *           }
		 *         }
		 *       }
		 *     },
		 *     "id": "/ops/audit",
		 *     "acceptedResourceRoles": [
		 *       "public-facing"
		 *     ],
		 *     "args": [
		 *       "some value"
		 *     ],
		 *     "backoffFactor": 1.15,
		 *     "backoffSeconds": 1,
		 *     "cmd": "some value",
		 *     "constraints": [
		 *       [
		 *         "hostname",
		 *         "UNIQUE"
		 *       ]
		 *     ],
		 *     "container": {
		 *       "type": "DOCKER",
		 *       "docker": {
		 *         "credential": {
		 *           "principal": "some value",
		 *           "secret": "some value"
		 *         },
		 *         "pullConfig": {
		 *           "secret": "some value"
		 *         },
		 *         "forcePullImage": false,
		 *         "image": "some value",
		 *         "network": "some value",
		 *         "parameters": [
		 *           {
		 *             "key": "some value",
		 *             "value": "some value"
		 *           }
		 *         ],
		 *         "portMappings": [
		 *           {
		 *             "containerPort": 0,
		 *             "hostPort": 1,
		 *             "labels": {},
		 *             "name": "some value",
		 *             "protocol": "tcp",
		 *             "servicePort": 0,
		 *             "networkNames": [
		 *               "some value"
		 *             ]
		 *           }
		 *         ],
		 *         "privileged": false
		 *       },
		 *       "linuxInfo": {
		 *         "seccomp": {
		 *           "profileName": "some value",
		 *           "unconfined": false
		 *         },
		 *         "ipcInfo": {
		 *           "mode": "some value",
		 *           "shmSize": 1
		 *         }
		 *       },
		 *       "volumes": [
		 *         {
		 *           "containerPath": "some value",
		 *           "persistent": {
		 *             "type": "some value",
		 *             "size": 1,
		 *             "maxSize": 1,
		 *             "profileName": "some value",
		 *             "constraints": [
		 *               [
		 *                 "some value"
		 *               ]
		 *             ]
		 *           },
		 *           "mode": "some value"
		 *         }
		 *       ],
		 *       "portMappings": [
		 *         {
		 *           "containerPort": 0,
		 *           "hostPort": 1,
		 *           "labels": {},
		 *           "name": "some value",
		 *           "protocol": "tcp",
		 *           "servicePort": 0,
		 *           "networkNames": [
		 *             "some value"
		 *           ]
		 *         }
		 *       ]
		 *     },
		 *     "cpus": 1,
		 *     "dependencies": [
		 *       "/ops/audit"
		 *     ],
		 *     "disk": 0,
		 *     "env": {},
		 *     "executor": "",
		 *     "executorResources": {
		 *       "cpus": 0.2,
		 *       "mem": 512,
		 *       "disk": 50
		 *     },
		 *     "fetch": [
		 *       {
		 *         "uri": "http://download.me/file.tgz",
		 *         "extract": true,
		 *         "executable": true,
		 *         "cache": false
		 *       }
		 *     ],
		 *     "healthChecks": [
		 *       {
		 *         "command": {
		 *           "value": "some value"
		 *         },
		 *         "gracePeriodSeconds": 300,
		 *         "ignoreHttp1xx": true,
		 *         "intervalSeconds": 60,
		 *         "maxConsecutiveFailures": 3,
		 *         "path": "/path/to/health",
		 *         "port": 1,
		 *         "portIndex": 1,
		 *         "protocol": "HTTP",
		 *         "ipProtocol": "IPv4",
		 *         "timeoutSeconds": 20,
		 *         "delaySeconds": 15
		 *       }
		 *     ],
		 *     "check": {
		 *       "http": {
		 *         "portIndex": 1,
		 *         "port": 1,
		 *         "path": "some value",
		 *         "scheme": "HTTP"
		 *       },
		 *       "tcp": {
		 *         "portIndex": 1,
		 *         "port": 1
		 *       },
		 *       "exec": {
		 *         "command": {
		 *           "shell": "some value"
		 *         }
		 *       },
		 *       "intervalSeconds": 60,
		 *       "timeoutSeconds": 20,
		 *       "delaySeconds": 15
		 *     },
		 *     "instances": 1,
		 *     "labels": {},
		 *     "maxLaunchDelaySeconds": 300,
		 *     "mem": 128,
		 *     "gpus": 0,
		 *     "ipAddress": {
		 *       "discovery": {
		 *         "ports": [
		 *           {
		 *             "number": 0,
		 *             "name": "some value",
		 *             "protocol": "tcp",
		 *             "labels": {}
		 *           }
		 *         ]
		 *       },
		 *       "groups": [
		 *         "some value"
		 *       ],
		 *       "labels": {},
		 *       "networkName": "some value"
		 *     },
		 *     "networks": [
		 *       {
		 *         "name": "some value",
		 *         "mode": "container",
		 *         "labels": {}
		 *       }
		 *     ],
		 *     "ports": [
		 *       0
		 *     ],
		 *     "portDefinitions": [
		 *       {
		 *         "port": 0,
		 *         "labels": {},
		 *         "name": "some value",
		 *         "protocol": "tcp"
		 *       }
		 *     ],
		 *     "readinessChecks": [
		 *       {
		 *         "name": "readinessCheck",
		 *         "protocol": "HTTP",
		 *         "path": "/path/to/health.",
		 *         "portName": "http-api",
		 *         "intervalSeconds": 30,
		 *         "timeoutSeconds": 10,
		 *         "httpStatusCodesForReady": [
		 *           1
		 *         ],
		 *         "preserveLastResponse": false
		 *       }
		 *     ],
		 *     "residency": {
		 *       "relaunchEscalationTimeoutSeconds": 3600,
		 *       "taskLostBehavior": "WAIT_FOREVER"
		 *     },
		 *     "resourceLimits": {
		 *       "cpus": "some value",
		 *       "mem": "some value"
		 *     },
		 *     "requirePorts": false,
		 *     "secrets": {},
		 *     "taskKillGracePeriodSeconds": 1,
		 *     "upgradeStrategy": {
		 *       "maximumOverCapacity": 1,
		 *       "minimumHealthCapacity": 1
		 *     },
		 *     "uris": [
		 *       "some value"
		 *     ],
		 *     "user": "some value",
		 *     "version": "some value",
		 *     "versionInfo": {
		 *       "lastScalingAt": "some value",
		 *       "lastConfigChangeAt": "some value"
		 *     },
		 *     "killSelection": "YOUNGEST_FIRST",
		 *     "unreachableStrategy": "disabled",
		 *     "tty": true,
		 *     "role": "some value"
		 *   }
		 * ]
		 */
		AppInfoAsArray: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.AppInfo(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   "some value"
		 * ]
		 */
		DateTimeTypeAsArray: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.DateTimeType(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * {
		 *   "appId": "/ops/audit",
		 *   "host": "some value",
		 *   "message": "some value",
		 *   "state": "some value",
		 *   "taskId": "some value",
		 *   "timestamp": "some value",
		 *   "version": "some value",
		 *   "slaveId": "some value"
		 * }
		 */
		TaskFailure: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.appId == null) {
					errors.push(new RAMLError(path.concat(['appId']), context, "PROP_IS_MISSING", {name: 'appId'}));
				} else {
					errors = errors.concat(Validators.PathId(value.appId, path.concat(['appId'])));
				}
				if (value.host == null) {
					errors.push(new RAMLError(path.concat(['host']), context, "PROP_IS_MISSING", {name: 'host'}));
				} else {
					errors = errors.concat(Validators.StringType(value.host, path.concat(['host'])));
				}
				if (value.message == null) {
					errors.push(new RAMLError(path.concat(['message']), context, "PROP_IS_MISSING", {name: 'message'}));
				} else {
					errors = errors.concat(Validators.StringType(value.message, path.concat(['message'])));
				}
				if (value.state == null) {
					errors.push(new RAMLError(path.concat(['state']), context, "PROP_IS_MISSING", {name: 'state'}));
				} else {
					errors = errors.concat(Validators.StringType(value.state, path.concat(['state'])));
				}
				if (value.taskId == null) {
					errors.push(new RAMLError(path.concat(['taskId']), context, "PROP_IS_MISSING", {name: 'taskId'}));
				} else {
					errors = errors.concat(Validators.StringType(value.taskId, path.concat(['taskId'])));
				}
				if (value.timestamp == null) {
					errors.push(new RAMLError(path.concat(['timestamp']), context, "PROP_IS_MISSING", {name: 'timestamp'}));
				} else {
					errors = errors.concat(Validators.DateTimeType(value.timestamp, path.concat(['timestamp'])));
				}
				if (value.version == null) {
					errors.push(new RAMLError(path.concat(['version']), context, "PROP_IS_MISSING", {name: 'version'}));
				} else {
					errors = errors.concat(Validators.DateTimeType(value.version, path.concat(['version'])));
				}
				if (value.slaveId != null) {
					errors = errors.concat(Validators.StringType(value.slaveId, path.concat(['slaveId'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "startedAfterLastScaling": {
		 *     "stats": {
		 *       "counts": {
		 *         "staged": 1,
		 *         "running": 1,
		 *         "healthy": 1,
		 *         "unhealthy": 1
		 *       },
		 *       "lifeTime": {
		 *         "averageSeconds": 1,
		 *         "medianSeconds": 1
		 *       }
		 *     }
		 *   },
		 *   "withLatestConfig": {
		 *     "stats": {
		 *       "counts": {
		 *         "staged": 1,
		 *         "running": 1,
		 *         "healthy": 1,
		 *         "unhealthy": 1
		 *       },
		 *       "lifeTime": {
		 *         "averageSeconds": 1,
		 *         "medianSeconds": 1
		 *       }
		 *     }
		 *   },
		 *   "withOutdatedConfig": {
		 *     "stats": {
		 *       "counts": {
		 *         "staged": 1,
		 *         "running": 1,
		 *         "healthy": 1,
		 *         "unhealthy": 1
		 *       },
		 *       "lifeTime": {
		 *         "averageSeconds": 1,
		 *         "medianSeconds": 1
		 *       }
		 *     }
		 *   },
		 *   "totalSummary": {
		 *     "stats": {
		 *       "counts": {
		 *         "staged": 1,
		 *         "running": 1,
		 *         "healthy": 1,
		 *         "unhealthy": 1
		 *       },
		 *       "lifeTime": {
		 *         "averageSeconds": 1,
		 *         "medianSeconds": 1
		 *       }
		 *     }
		 *   }
		 * }
		 */
		TaskStatsByVersion: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.startedAfterLastScaling != null) {
					errors = errors.concat(Validators.Stats(value.startedAfterLastScaling, path.concat(['startedAfterLastScaling'])));
				}
				if (value.withLatestConfig != null) {
					errors = errors.concat(Validators.Stats(value.withLatestConfig, path.concat(['withLatestConfig'])));
				}
				if (value.withOutdatedConfig != null) {
					errors = errors.concat(Validators.Stats(value.withOutdatedConfig, path.concat(['withOutdatedConfig'])));
				}
				if (value.totalSummary != null) {
					errors = errors.concat(Validators.Stats(value.totalSummary, path.concat(['totalSummary'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		StringType: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
			}
			return errors;
		},
	
		/**
		 * [
		 *   "hostname",
		 *   "UNIQUE"
		 * ]
		 */
		AppConstraint: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.StringType(value, path.concat([i]))
					);
				}, errors);
				if (value.length < 2) {
					errors.push(new RAMLError(path, context, "ITEMS_MIN", {"value":2}));
				}
				if (value.length > 3) {
					errors.push(new RAMLError(path, context, "ITEMS_MAX", {"value":3}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "credential": {
		 *     "principal": "some value",
		 *     "secret": "some value"
		 *   },
		 *   "pullConfig": {
		 *     "secret": "some value"
		 *   },
		 *   "forcePullImage": false,
		 *   "image": "some value",
		 *   "network": "some value",
		 *   "parameters": [
		 *     {
		 *       "key": "some value",
		 *       "value": "some value"
		 *     }
		 *   ],
		 *   "portMappings": [
		 *     {
		 *       "containerPort": 0,
		 *       "hostPort": 1,
		 *       "labels": {},
		 *       "name": "some value",
		 *       "protocol": "tcp",
		 *       "servicePort": 0,
		 *       "networkNames": [
		 *         "some value"
		 *       ]
		 *     }
		 *   ],
		 *   "privileged": false
		 * }
		 */
		DockerContainer: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.credential != null) {
					errors = errors.concat(Validators.DockerCredentials(value.credential, path.concat(['credential'])));
				}
				if (value.pullConfig != null) {
					errors = errors.concat(Validators.DockerPullConfig(value.pullConfig, path.concat(['pullConfig'])));
				}
				if (value.forcePullImage != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.forcePullImage, path.concat(['forcePullImage'])));
				}
				if (value.image == null) {
					errors.push(new RAMLError(path.concat(['image']), context, "PROP_IS_MISSING", {name: 'image'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.image, path.concat(['image'])));
				}
				if (value.network != null) {
					errors = errors.concat(PrivateValidators.inlineDockerNetwork_97392176b9645001a877ed1e9b844cc9(value.network, path.concat(['network'])));
				}
				if (value.parameters != null) {
					errors = errors.concat(PrivateValidators.inlineDockerParameter_c16b318f4fe8b85b2a89ed06127bb779(value.parameters, path.concat(['parameters'])));
				}
				if (value.portMappings != null) {
					errors = errors.concat(PrivateValidators.inlineContainerPortMapping_c16b318f4fe8b85b2a89ed06127bb779(value.portMappings, path.concat(['portMappings'])));
				}
				if (value.privileged != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.privileged, path.concat(['privileged'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "seccomp": {
		 *     "profileName": "some value",
		 *     "unconfined": false
		 *   },
		 *   "ipcInfo": {
		 *     "mode": "some value",
		 *     "shmSize": 1
		 *   }
		 * }
		 */
		LinuxInfo: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.seccomp != null) {
					errors = errors.concat(Validators.Seccomp(value.seccomp, path.concat(['seccomp'])));
				}
				if (value.ipcInfo != null) {
					errors = errors.concat(Validators.IPCInfo(value.ipcInfo, path.concat(['ipcInfo'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		EnvVarValueOrSecret: function(value, path) {
			var errors = [];
			path = path || [];
			errors = errors.concat([
				Validators.EnvVarValue(value, path),
				Validators.EnvVarSecret(value, path),
			].sort(function(a, b) {
				return a.length - b.length;
			})[0]);
			return errors;
		},
	
		/**
		 * "{\n  \"uri\": \"http://download.me/file.tgz\",\n  \"extract\": true,\n  \"executable\": true,\n  \"cache\": false\n}\n"
		 */
		Artifact: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.uri == null) {
					errors.push(new RAMLError(path.concat(['uri']), context, "PROP_IS_MISSING", {name: 'uri'}));
				} else {
					errors = errors.concat(Validators.Uri(value.uri, path.concat(['uri'])));
				}
				if (value.extract != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.extract, path.concat(['extract'])));
				}
				if (value.executable != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.executable, path.concat(['executable'])));
				}
				if (value.cache != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.cache, path.concat(['cache'])));
				}
				if (value.destPath != null) {
					errors = errors.concat(Validators.Path(value.destPath, path.concat(['destPath'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "command": {
		 *     "value": "some value"
		 *   },
		 *   "gracePeriodSeconds": 300,
		 *   "ignoreHttp1xx": true,
		 *   "intervalSeconds": 60,
		 *   "maxConsecutiveFailures": 3,
		 *   "path": "/path/to/health",
		 *   "port": 1,
		 *   "portIndex": 1,
		 *   "protocol": "HTTP",
		 *   "ipProtocol": "IPv4",
		 *   "timeoutSeconds": 20,
		 *   "delaySeconds": 15
		 * }
		 */
		AppHealthCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.command != null) {
					errors = errors.concat(Validators.AppCommandCheck(value.command, path.concat(['command'])));
				}
				if (value.gracePeriodSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.gracePeriodSeconds, path.concat(['gracePeriodSeconds'])));
				}
				if (value.ignoreHttp1xx != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.ignoreHttp1xx, path.concat(['ignoreHttp1xx'])));
				}
				if (value.intervalSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.intervalSeconds, path.concat(['intervalSeconds'])));
				}
				if (value.maxConsecutiveFailures != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.maxConsecutiveFailures, path.concat(['maxConsecutiveFailures'])));
				}
				if (value.path != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.path, path.concat(['path'])));
				}
				if (value.port != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.port, path.concat(['port'])));
				}
				if (value.portIndex != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.portIndex, path.concat(['portIndex'])));
				}
				if (value.protocol != null) {
					errors = errors.concat(PrivateValidators.inlineAppHealthCheckProtocol_d48bd5285cf380c9f3754324fc7feed2(value.protocol, path.concat(['protocol'])));
				}
				if (value.ipProtocol != null) {
					errors = errors.concat(PrivateValidators.inlineIpProtocol_380b8255e1ce5803ef2a53e316ec076b(value.ipProtocol, path.concat(['ipProtocol'])));
				}
				if (value.timeoutSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.timeoutSeconds, path.concat(['timeoutSeconds'])));
				}
				if (value.delaySeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.delaySeconds, path.concat(['delaySeconds'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "portIndex": 1,
		 *   "port": 1,
		 *   "path": "some value",
		 *   "scheme": "HTTP"
		 * }
		 */
		AppHttpCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.portIndex != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.portIndex, path.concat(['portIndex'])));
				}
				if (value.port != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.port, path.concat(['port'])));
				}
				if (value.path != null) {
					errors = errors.concat(Validators.Path(value.path, path.concat(['path'])));
				}
				if (value.scheme != null) {
					errors = errors.concat(PrivateValidators.inlineHttpScheme_9daecb70f32aeec3a0f8567d3168714f(value.scheme, path.concat(['scheme'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "portIndex": 1,
		 *   "port": 1
		 * }
		 */
		AppTcpCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.portIndex != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.portIndex, path.concat(['portIndex'])));
				}
				if (value.port != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.port, path.concat(['port'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "command": {
		 *     "shell": "some value"
		 *   }
		 * }
		 */
		CommandCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.command == null) {
					errors.push(new RAMLError(path.concat(['command']), context, "PROP_IS_MISSING", {name: 'command'}));
				} else {
					errors = errors.concat(Validators.MesosCommand(value.command, path.concat(['command'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "ports": [
		 *     {
		 *       "number": 0,
		 *       "name": "some value",
		 *       "protocol": "tcp",
		 *       "labels": {}
		 *     }
		 *   ]
		 * }
		 */
		IpDiscovery: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.ports != null) {
					errors = errors.concat(PrivateValidators.inlineIpDiscoveryPort_c16b318f4fe8b85b2a89ed06127bb779(value.ports, path.concat(['ports'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "mode": "container",
		 *   "labels": {}
		 * }
		 */
		Network: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name != null) {
					errors = errors.concat(PrivateValidators.inlineNetworkName_d5930fd0fc0461b8008a443d59699e32(value.name, path.concat(['name'])));
				}
				if (value.mode != null) {
					errors = errors.concat(PrivateValidators.inlineNetworkMode_4cccbb4c3c88d1078bf95c85c1818632(value.mode, path.concat(['mode'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
			}
			return errors;
		},
	
		/**
		 * 0
		 */
		AnyPort: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
				if (value > 65535) {
					errors.push(new RAMLError(path, context, "NUMBER_MAX", {"value":65535}));
				}
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "port": 0,
		 *   "labels": {},
		 *   "name": "some value",
		 *   "protocol": "tcp"
		 * }
		 */
		PortDefinition: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.port != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.port, path.concat(['port'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
				if (value.name != null) {
					errors = errors.concat(PrivateValidators.inlineLegacyName_6b57bcbac18a961e5de097e580b2c3d1(value.name, path.concat(['name'])));
				}
				if (value.protocol != null) {
					errors = errors.concat(PrivateValidators.inlineNetworkProtocol_929ca93e4a730214947afb3b3214f365(value.protocol, path.concat(['protocol'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "readinessCheck",
		 *   "protocol": "HTTP",
		 *   "path": "/path/to/health.",
		 *   "portName": "http-api",
		 *   "intervalSeconds": 30,
		 *   "timeoutSeconds": 10,
		 *   "httpStatusCodesForReady": [
		 *     1
		 *   ],
		 *   "preserveLastResponse": false
		 * }
		 */
		ReadinessCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.name, path.concat(['name'])));
				}
				if (value.protocol != null) {
					errors = errors.concat(PrivateValidators.inlineHttpScheme_9daecb70f32aeec3a0f8567d3168714f(value.protocol, path.concat(['protocol'])));
				}
				if (value.path != null) {
					errors = errors.concat(PrivateValidators.inlinePath_293be7de6c009b1ca492ed5c5b0eafa1(value.path, path.concat(['path'])));
				}
				if (value.portName != null) {
					errors = errors.concat(PrivateValidators.inlineLegacyName_6b57bcbac18a961e5de097e580b2c3d1(value.portName, path.concat(['portName'])));
				}
				if (value.intervalSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.intervalSeconds, path.concat(['intervalSeconds'])));
				}
				if (value.timeoutSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_c93f718de273ed296fcdc5ae52e1ebf3(value.timeoutSeconds, path.concat(['timeoutSeconds'])));
				}
				if (value.httpStatusCodesForReady != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_9571dae39de95a138d04ae36ba2376aa(value.httpStatusCodesForReady, path.concat(['httpStatusCodesForReady'])));
				}
				if (value.preserveLastResponse != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.preserveLastResponse, path.concat(['preserveLastResponse'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		ResourceLimit: function(value, path) {
			var errors = [];
			path = path || [];
			return errors;
		},
	
		/**
		 * {
		 *   "source": "some value"
		 * }
		 */
		SecretDef: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.source == null) {
					errors.push(new RAMLError(path.concat(['source']), context, "PROP_IS_MISSING", {name: 'source'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.source, path.concat(['source'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		Uri: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
				if (value.length > 1024) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":1024}));
				}
			}
			return errors;
		},
	
		/**
		 * "disabled"
		 */
		UnreachableDisabled: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[3].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"disabled"}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "inactiveAfterSeconds": 0,
		 *   "expungeAfterSeconds": 0
		 * }
		 */
		UnreachableEnabled: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.inactiveAfterSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.inactiveAfterSeconds, path.concat(['inactiveAfterSeconds'])));
				}
				if (value.expungeAfterSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.expungeAfterSeconds, path.concat(['expungeAfterSeconds'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		DateTimeType: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(new Date(value).getTime())) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_DATETIME"));
			} else {
			}
			return errors;
		},
	
		/**
		 * {
		 *   "id": "some value"
		 * }
		 */
		Identifiable: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.id == null) {
					errors.push(new RAMLError(path.concat(['id']), context, "PROP_IS_MISSING", {name: 'id'}));
				} else {
					errors = errors.concat(Validators.StringType(value.id, path.concat(['id'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "taskId": "some value",
		 *   "ready": true,
		 *   "lastResponse": {
		 *     "status": 1,
		 *     "contentType": "some value",
		 *     "body": "some value"
		 *   }
		 * }
		 */
		TaskReadinessCheckResult: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.name, path.concat(['name'])));
				}
				if (value.taskId == null) {
					errors.push(new RAMLError(path.concat(['taskId']), context, "PROP_IS_MISSING", {name: 'taskId'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineInstanceId_1c1fec05deb9479959f10ad40b7f76ab(value.taskId, path.concat(['taskId'])));
				}
				if (value.ready == null) {
					errors.push(new RAMLError(path.concat(['ready']), context, "PROP_IS_MISSING", {name: 'ready'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.ready, path.concat(['ready'])));
				}
				if (value.lastResponse != null) {
					errors = errors.concat(Validators.ReadinessCheckHttpResponse(value.lastResponse, path.concat(['lastResponse'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "appId": "/ops/audit",
		 *   "healthCheckResults": [
		 *     {
		 *       "alive": true,
		 *       "consecutiveFailures": 1,
		 *       "firstSuccess": "some value",
		 *       "instanceId": "some value",
		 *       "lastSuccess": "some value",
		 *       "lastFailure": "some value",
		 *       "lastFailureCause": "some value"
		 *     }
		 *   ],
		 *   "checkResult": {
		 *     "http": {
		 *       "statusCode": 1
		 *     },
		 *     "tcp": {
		 *       "succeeded": true
		 *     },
		 *     "command": {
		 *       "exitCode": 1
		 *     }
		 *   },
		 *   "host": "some value",
		 *   "id": "some value",
		 *   "ipAddresses": [
		 *     {
		 *       "ipAddress": "some value",
		 *       "protocol": "some value"
		 *     }
		 *   ],
		 *   "ports": [
		 *     1
		 *   ],
		 *   "servicePorts": [
		 *     1
		 *   ],
		 *   "slaveId": "some value",
		 *   "state": "some value",
		 *   "stagedAt": "some value",
		 *   "startedAt": "some value",
		 *   "version": "some value",
		 *   "localVolumes": [
		 *     {
		 *       "runSpecId": "/ops/audit",
		 *       "containerPath": "some value",
		 *       "uuid": "some value",
		 *       "persistenceId": "some value"
		 *     }
		 *   ],
		 *   "region": "some value",
		 *   "zone": "some value",
		 *   "role": "some value"
		 * }
		 */
		Task: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.appId == null) {
					errors.push(new RAMLError(path.concat(['appId']), context, "PROP_IS_MISSING", {name: 'appId'}));
				} else {
					errors = errors.concat(Validators.PathId(value.appId, path.concat(['appId'])));
				}
				if (value.healthCheckResults != null) {
					errors = errors.concat(Validators.HealthAsArray(value.healthCheckResults, path.concat(['healthCheckResults'])));
				}
				if (value.checkResult != null) {
					errors = errors.concat(Validators.CheckStatus(value.checkResult, path.concat(['checkResult'])));
				}
				if (value.host == null) {
					errors.push(new RAMLError(path.concat(['host']), context, "PROP_IS_MISSING", {name: 'host'}));
				} else {
					errors = errors.concat(Validators.StringType(value.host, path.concat(['host'])));
				}
				if (value.id == null) {
					errors.push(new RAMLError(path.concat(['id']), context, "PROP_IS_MISSING", {name: 'id'}));
				} else {
					errors = errors.concat(Validators.StringType(value.id, path.concat(['id'])));
				}
				if (value.ipAddresses != null) {
					errors = errors.concat(Validators.IpAddrAsArray(value.ipAddresses, path.concat(['ipAddresses'])));
				}
				if (value.ports != null) {
					errors = errors.concat(Validators.PortAsArray(value.ports, path.concat(['ports'])));
				}
				if (value.servicePorts != null) {
					errors = errors.concat(Validators.PortAsArray(value.servicePorts, path.concat(['servicePorts'])));
				}
				if (value.slaveId != null) {
					errors = errors.concat(Validators.StringType(value.slaveId, path.concat(['slaveId'])));
				}
				if (value.state == null) {
					errors.push(new RAMLError(path.concat(['state']), context, "PROP_IS_MISSING", {name: 'state'}));
				} else {
					errors = errors.concat(Validators.MesosTaskState(value.state, path.concat(['state'])));
				}
				if (value.stagedAt != null) {
					errors = errors.concat(Validators.StringType(value.stagedAt, path.concat(['stagedAt'])));
				}
				if (value.startedAt != null) {
					errors = errors.concat(Validators.StringType(value.startedAt, path.concat(['startedAt'])));
				}
				if (value.version != null) {
					errors = errors.concat(Validators.StringType(value.version, path.concat(['version'])));
				}
				if (value.localVolumes != null) {
					errors = errors.concat(Validators.LocalVolumeIdAsArray(value.localVolumes, path.concat(['localVolumes'])));
				}
				if (value.region != null) {
					errors = errors.concat(Validators.StringType(value.region, path.concat(['region'])));
				}
				if (value.zone != null) {
					errors = errors.concat(Validators.StringType(value.zone, path.concat(['zone'])));
				}
				if (value.role == null) {
					errors.push(new RAMLError(path.concat(['role']), context, "PROP_IS_MISSING", {name: 'role'}));
				} else {
					errors = errors.concat(Validators.StringType(value.role, path.concat(['role'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "stats": {
		 *     "counts": {
		 *       "staged": 1,
		 *       "running": 1,
		 *       "healthy": 1,
		 *       "unhealthy": 1
		 *     },
		 *     "lifeTime": {
		 *       "averageSeconds": 1,
		 *       "medianSeconds": 1
		 *     }
		 *   }
		 * }
		 */
		Stats: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.stats == null) {
					errors.push(new RAMLError(path.concat(['stats']), context, "PROP_IS_MISSING", {name: 'stats'}));
				} else {
					errors = errors.concat(Validators.TaskStats(value.stats, path.concat(['stats'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "principal": "some value",
		 *   "secret": "some value"
		 * }
		 */
		DockerCredentials: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.principal == null) {
					errors.push(new RAMLError(path.concat(['principal']), context, "PROP_IS_MISSING", {name: 'principal'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.principal, path.concat(['principal'])));
				}
				if (value.secret != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.secret, path.concat(['secret'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "secret": "some value"
		 * }
		 */
		DockerPullConfig: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.secret == null) {
					errors.push(new RAMLError(path.concat(['secret']), context, "PROP_IS_MISSING", {name: 'secret'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.secret, path.concat(['secret'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "profileName": "some value",
		 *   "unconfined": false
		 * }
		 */
		Seccomp: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.profileName != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.profileName, path.concat(['profileName'])));
				}
				if (value.unconfined == null) {
					errors.push(new RAMLError(path.concat(['unconfined']), context, "PROP_IS_MISSING", {name: 'unconfined'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.unconfined, path.concat(['unconfined'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "mode": "some value",
		 *   "shmSize": 1
		 * }
		 */
		IPCInfo: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.mode == null) {
					errors.push(new RAMLError(path.concat(['mode']), context, "PROP_IS_MISSING", {name: 'mode'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIPCMode_03e037d41990b538b7d9c54cbc0f5f58(value.mode, path.concat(['mode'])));
				}
				if (value.shmSize != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_d5ab06b03ff21f3a6085461bfd6e5065(value.shmSize, path.concat(['shmSize'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "containerPath": "some value",
		 *   "persistent": {
		 *     "type": "some value",
		 *     "size": 1,
		 *     "maxSize": 1,
		 *     "profileName": "some value",
		 *     "constraints": [
		 *       [
		 *         "some value"
		 *       ]
		 *     ]
		 *   },
		 *   "mode": "some value"
		 * }
		 */
		AppVolume: function(value, path) {
			var errors = [];
			path = path || [];
			errors = errors.concat([
				Validators.AppPersistentVolume(value, path),
				Validators.AppExternalVolume(value, path),
				Validators.AppSecretVolume(value, path),
				Validators.AppHostVolume(value, path),
			].sort(function(a, b) {
				return a.length - b.length;
			})[0]);
			return errors;
		},
	
		/**
		 * {
		 *   "containerPort": 0,
		 *   "hostPort": 1,
		 *   "labels": {},
		 *   "name": "some value",
		 *   "protocol": "tcp",
		 *   "servicePort": 0,
		 *   "networkNames": [
		 *     "some value"
		 *   ]
		 * }
		 */
		ContainerPortMapping: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.containerPort == null) {
					errors.push(new RAMLError(path.concat(['containerPort']), context, "PROP_IS_MISSING", {name: 'containerPort'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.containerPort, path.concat(['containerPort'])));
				}
				if (value.hostPort != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.hostPort, path.concat(['hostPort'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
				if (value.name != null) {
					errors = errors.concat(PrivateValidators.inlineLegacyName_6b57bcbac18a961e5de097e580b2c3d1(value.name, path.concat(['name'])));
				}
				if (value.protocol != null) {
					errors = errors.concat(PrivateValidators.inlineNetworkProtocol_929ca93e4a730214947afb3b3214f365(value.protocol, path.concat(['protocol'])));
				}
				if (value.servicePort != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.servicePort, path.concat(['servicePort'])));
				}
				if (value.networkNames != null) {
					errors = errors.concat(PrivateValidators.inlineName_c16b318f4fe8b85b2a89ed06127bb779(value.networkNames, path.concat(['networkNames'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		EnvVarValue: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
			}
			return errors;
		},
	
		/**
		 * {
		 *   "secret": "some value"
		 * }
		 */
		EnvVarSecret: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.secret == null) {
					errors.push(new RAMLError(path.concat(['secret']), context, "PROP_IS_MISSING", {name: 'secret'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.secret, path.concat(['secret'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		Path: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
				if (value.length > 1024) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":1024}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "value": "some value"
		 * }
		 */
		AppCommandCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.value == null) {
					errors.push(new RAMLError(path.concat(['value']), context, "PROP_IS_MISSING", {name: 'value'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.value, path.concat(['value'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "shell": "some value"
		 * }
		 */
		MesosCommand: function(value, path) {
			var errors = [];
			path = path || [];
			return errors;
		},
	
		/**
		 * {
		 *   "status": 1,
		 *   "contentType": "some value",
		 *   "body": "some value"
		 * }
		 */
		ReadinessCheckHttpResponse: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.status == null) {
					errors.push(new RAMLError(path.concat(['status']), context, "PROP_IS_MISSING", {name: 'status'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.status, path.concat(['status'])));
				}
				if (value.contentType == null) {
					errors.push(new RAMLError(path.concat(['contentType']), context, "PROP_IS_MISSING", {name: 'contentType'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.contentType, path.concat(['contentType'])));
				}
				if (value.body == null) {
					errors.push(new RAMLError(path.concat(['body']), context, "PROP_IS_MISSING", {name: 'body'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.body, path.concat(['body'])));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "alive": true,
		 *     "consecutiveFailures": 1,
		 *     "firstSuccess": "some value",
		 *     "instanceId": "some value",
		 *     "lastSuccess": "some value",
		 *     "lastFailure": "some value",
		 *     "lastFailureCause": "some value"
		 *   }
		 * ]
		 */
		HealthAsArray: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Health(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * {
		 *   "http": {
		 *     "statusCode": 1
		 *   },
		 *   "tcp": {
		 *     "succeeded": true
		 *   },
		 *   "command": {
		 *     "exitCode": 1
		 *   }
		 * }
		 */
		CheckStatus: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.http != null) {
					errors = errors.concat(Validators.HttpCheckStatus(value.http, path.concat(['http'])));
				}
				if (value.tcp != null) {
					errors = errors.concat(Validators.TCPCheckStatus(value.tcp, path.concat(['tcp'])));
				}
				if (value.command != null) {
					errors = errors.concat(Validators.CommandCheckStatus(value.command, path.concat(['command'])));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "ipAddress": "some value",
		 *     "protocol": "some value"
		 *   }
		 * ]
		 */
		IpAddrAsArray: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.IpAddr(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   1
		 * ]
		 */
		PortAsArray: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Port(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		MesosTaskState: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[10].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"task_error, task_failed, task_finished, task_killed, task_killing, task_running, task_staging, task_starting, task_unreachable, task_unknown, task_gone, task_dropped"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "runSpecId": "/ops/audit",
		 *     "containerPath": "some value",
		 *     "uuid": "some value",
		 *     "persistenceId": "some value"
		 *   }
		 * ]
		 */
		LocalVolumeIdAsArray: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.LocalVolumeId(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * {
		 *   "counts": {
		 *     "staged": 1,
		 *     "running": 1,
		 *     "healthy": 1,
		 *     "unhealthy": 1
		 *   },
		 *   "lifeTime": {
		 *     "averageSeconds": 1,
		 *     "medianSeconds": 1
		 *   }
		 * }
		 */
		TaskStats: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.counts == null) {
					errors.push(new RAMLError(path.concat(['counts']), context, "PROP_IS_MISSING", {name: 'counts'}));
				} else {
					errors = errors.concat(Validators.TaskCounts(value.counts, path.concat(['counts'])));
				}
				if (value.lifeTime != null) {
					errors = errors.concat(Validators.TaskLifeTime(value.lifeTime, path.concat(['lifeTime'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "key": "some value",
		 *   "value": "some value"
		 * }
		 */
		DockerParameter: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.key == null) {
					errors.push(new RAMLError(path.concat(['key']), context, "PROP_IS_MISSING", {name: 'key'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.key, path.concat(['key'])));
				}
				if (value.value == null) {
					errors.push(new RAMLError(path.concat(['value']), context, "PROP_IS_MISSING", {name: 'value'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.value, path.concat(['value'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "containerPath": "some value",
		 *   "persistent": {
		 *     "type": "some value",
		 *     "size": 1,
		 *     "maxSize": 1,
		 *     "profileName": "some value",
		 *     "constraints": [
		 *       [
		 *         "some value"
		 *       ]
		 *     ]
		 *   },
		 *   "mode": "some value"
		 * }
		 */
		AppPersistentVolume: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.containerPath == null) {
					errors.push(new RAMLError(path.concat(['containerPath']), context, "PROP_IS_MISSING", {name: 'containerPath'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.containerPath, path.concat(['containerPath'])));
				}
				if (value.persistent == null) {
					errors.push(new RAMLError(path.concat(['persistent']), context, "PROP_IS_MISSING", {name: 'persistent'}));
				} else {
					errors = errors.concat(Validators.PersistentVolumeInfo(value.persistent, path.concat(['persistent'])));
				}
				if (value.mode == null) {
					errors.push(new RAMLError(path.concat(['mode']), context, "PROP_IS_MISSING", {name: 'mode'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineReadMode_ebf93bc60688d31156f7c38ab762ecab(value.mode, path.concat(['mode'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "containerPath": "some value",
		 *   "external": {
		 *     "size": 1,
		 *     "name": "some value",
		 *     "provider": "some value",
		 *     "options": {},
		 *     "shared": false
		 *   },
		 *   "mode": "some value"
		 * }
		 */
		AppExternalVolume: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.containerPath == null) {
					errors.push(new RAMLError(path.concat(['containerPath']), context, "PROP_IS_MISSING", {name: 'containerPath'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.containerPath, path.concat(['containerPath'])));
				}
				if (value.external == null) {
					errors.push(new RAMLError(path.concat(['external']), context, "PROP_IS_MISSING", {name: 'external'}));
				} else {
					errors = errors.concat(Validators.ExternalVolumeInfo(value.external, path.concat(['external'])));
				}
				if (value.mode == null) {
					errors.push(new RAMLError(path.concat(['mode']), context, "PROP_IS_MISSING", {name: 'mode'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineReadMode_ebf93bc60688d31156f7c38ab762ecab(value.mode, path.concat(['mode'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "containerPath": "some value",
		 *   "secret": "some value"
		 * }
		 */
		AppSecretVolume: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.containerPath == null) {
					errors.push(new RAMLError(path.concat(['containerPath']), context, "PROP_IS_MISSING", {name: 'containerPath'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.containerPath, path.concat(['containerPath'])));
				}
				if (value.secret == null) {
					errors.push(new RAMLError(path.concat(['secret']), context, "PROP_IS_MISSING", {name: 'secret'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.secret, path.concat(['secret'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "containerPath": "some value",
		 *   "hostPath": "some value",
		 *   "mode": "some value"
		 * }
		 */
		AppHostVolume: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.containerPath == null) {
					errors.push(new RAMLError(path.concat(['containerPath']), context, "PROP_IS_MISSING", {name: 'containerPath'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.containerPath, path.concat(['containerPath'])));
				}
				if (value.hostPath == null) {
					errors.push(new RAMLError(path.concat(['hostPath']), context, "PROP_IS_MISSING", {name: 'hostPath'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.hostPath, path.concat(['hostPath'])));
				}
				if (value.mode == null) {
					errors.push(new RAMLError(path.concat(['mode']), context, "PROP_IS_MISSING", {name: 'mode'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineReadMode_ebf93bc60688d31156f7c38ab762ecab(value.mode, path.concat(['mode'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "number": 0,
		 *   "name": "some value",
		 *   "protocol": "tcp",
		 *   "labels": {}
		 * }
		 */
		IpDiscoveryPort: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.number != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.number, path.concat(['number'])));
				}
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineLegacyName_6b57bcbac18a961e5de097e580b2c3d1(value.name, path.concat(['name'])));
				}
				if (value.protocol != null) {
					errors = errors.concat(PrivateValidators.inlineNetworkProtocol_929ca93e4a730214947afb3b3214f365(value.protocol, path.concat(['protocol'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 */
		IntegerType: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
				if (value < 100) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":100}));
				}
				if (value > 999) {
					errors.push(new RAMLError(path, context, "NUMBER_MAX", {"value":999}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "alive": true,
		 *   "consecutiveFailures": 1,
		 *   "firstSuccess": "some value",
		 *   "instanceId": "some value",
		 *   "lastSuccess": "some value",
		 *   "lastFailure": "some value",
		 *   "lastFailureCause": "some value"
		 * }
		 */
		Health: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.alive == null) {
					errors.push(new RAMLError(path.concat(['alive']), context, "PROP_IS_MISSING", {name: 'alive'}));
				} else {
					errors = errors.concat(Validators.BooleanType(value.alive, path.concat(['alive'])));
				}
				if (value.consecutiveFailures == null) {
					errors.push(new RAMLError(path.concat(['consecutiveFailures']), context, "PROP_IS_MISSING", {name: 'consecutiveFailures'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineNumberType_d5ab06b03ff21f3a6085461bfd6e5065(value.consecutiveFailures, path.concat(['consecutiveFailures'])));
				}
				if (value.firstSuccess != null) {
					errors = errors.concat(Validators.StringType(value.firstSuccess, path.concat(['firstSuccess'])));
				}
				if (value.instanceId == null) {
					errors.push(new RAMLError(path.concat(['instanceId']), context, "PROP_IS_MISSING", {name: 'instanceId'}));
				} else {
					errors = errors.concat(Validators.InstanceId(value.instanceId, path.concat(['instanceId'])));
				}
				if (value.lastSuccess != null) {
					errors = errors.concat(Validators.StringType(value.lastSuccess, path.concat(['lastSuccess'])));
				}
				if (value.lastFailure != null) {
					errors = errors.concat(Validators.StringType(value.lastFailure, path.concat(['lastFailure'])));
				}
				if (value.lastFailureCause != null) {
					errors = errors.concat(Validators.StringType(value.lastFailureCause, path.concat(['lastFailureCause'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "statusCode": 1
		 * }
		 */
		HttpCheckStatus: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.statusCode == null) {
					errors.push(new RAMLError(path.concat(['statusCode']), context, "PROP_IS_MISSING", {name: 'statusCode'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.statusCode, path.concat(['statusCode'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "succeeded": true
		 * }
		 */
		TCPCheckStatus: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.succeeded == null) {
					errors.push(new RAMLError(path.concat(['succeeded']), context, "PROP_IS_MISSING", {name: 'succeeded'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.succeeded, path.concat(['succeeded'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "exitCode": 1
		 * }
		 */
		CommandCheckStatus: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.exitCode == null) {
					errors.push(new RAMLError(path.concat(['exitCode']), context, "PROP_IS_MISSING", {name: 'exitCode'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.exitCode, path.concat(['exitCode'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "ipAddress": "some value",
		 *   "protocol": "some value"
		 * }
		 */
		IpAddr: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.ipAddress == null) {
					errors.push(new RAMLError(path.concat(['ipAddress']), context, "PROP_IS_MISSING", {name: 'ipAddress'}));
				} else {
					errors = errors.concat(Validators.StringType(value.ipAddress, path.concat(['ipAddress'])));
				}
				if (value.protocol == null) {
					errors.push(new RAMLError(path.concat(['protocol']), context, "PROP_IS_MISSING", {name: 'protocol'}));
				} else {
					errors = errors.concat(Validators.IpProtocol(value.protocol, path.concat(['protocol'])));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 */
		Port: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (value < 1) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":1}));
				}
				if (value > 65535) {
					errors.push(new RAMLError(path, context, "NUMBER_MAX", {"value":65535}));
				}
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "runSpecId": "/ops/audit",
		 *   "containerPath": "some value",
		 *   "uuid": "some value",
		 *   "persistenceId": "some value"
		 * }
		 */
		LocalVolumeId: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.runSpecId == null) {
					errors.push(new RAMLError(path.concat(['runSpecId']), context, "PROP_IS_MISSING", {name: 'runSpecId'}));
				} else {
					errors = errors.concat(Validators.PathId(value.runSpecId, path.concat(['runSpecId'])));
				}
				if (value.containerPath == null) {
					errors.push(new RAMLError(path.concat(['containerPath']), context, "PROP_IS_MISSING", {name: 'containerPath'}));
				} else {
					errors = errors.concat(Validators.StringType(value.containerPath, path.concat(['containerPath'])));
				}
				if (value.uuid == null) {
					errors.push(new RAMLError(path.concat(['uuid']), context, "PROP_IS_MISSING", {name: 'uuid'}));
				} else {
					errors = errors.concat(Validators.StringType(value.uuid, path.concat(['uuid'])));
				}
				if (value.persistenceId == null) {
					errors.push(new RAMLError(path.concat(['persistenceId']), context, "PROP_IS_MISSING", {name: 'persistenceId'}));
				} else {
					errors = errors.concat(Validators.PersistenceId(value.persistenceId, path.concat(['persistenceId'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "staged": 1,
		 *   "running": 1,
		 *   "healthy": 1,
		 *   "unhealthy": 1
		 * }
		 */
		TaskCounts: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.staged == null) {
					errors.push(new RAMLError(path.concat(['staged']), context, "PROP_IS_MISSING", {name: 'staged'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.staged, path.concat(['staged'])));
				}
				if (value.running == null) {
					errors.push(new RAMLError(path.concat(['running']), context, "PROP_IS_MISSING", {name: 'running'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.running, path.concat(['running'])));
				}
				if (value.healthy == null) {
					errors.push(new RAMLError(path.concat(['healthy']), context, "PROP_IS_MISSING", {name: 'healthy'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.healthy, path.concat(['healthy'])));
				}
				if (value.unhealthy == null) {
					errors.push(new RAMLError(path.concat(['unhealthy']), context, "PROP_IS_MISSING", {name: 'unhealthy'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_d5ab06b03ff21f3a6085461bfd6e5065(value.unhealthy, path.concat(['unhealthy'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "averageSeconds": 1,
		 *   "medianSeconds": 1
		 * }
		 */
		TaskLifeTime: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.averageSeconds == null) {
					errors.push(new RAMLError(path.concat(['averageSeconds']), context, "PROP_IS_MISSING", {name: 'averageSeconds'}));
				} else {
					errors = errors.concat(Validators.NumberType(value.averageSeconds, path.concat(['averageSeconds'])));
				}
				if (value.medianSeconds == null) {
					errors.push(new RAMLError(path.concat(['medianSeconds']), context, "PROP_IS_MISSING", {name: 'medianSeconds'}));
				} else {
					errors = errors.concat(Validators.NumberType(value.medianSeconds, path.concat(['medianSeconds'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "type": "some value",
		 *   "size": 1,
		 *   "maxSize": 1,
		 *   "profileName": "some value",
		 *   "constraints": [
		 *     [
		 *       "some value"
		 *     ]
		 *   ]
		 * }
		 */
		PersistentVolumeInfo: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.type != null) {
					errors = errors.concat(Validators.PersistentVolumeType(value.type, path.concat(['type'])));
				}
				if (value.size == null) {
					errors.push(new RAMLError(path.concat(['size']), context, "PROP_IS_MISSING", {name: 'size'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.size, path.concat(['size'])));
				}
				if (value.maxSize != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.maxSize, path.concat(['maxSize'])));
				}
				if (value.profileName != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.profileName, path.concat(['profileName'])));
				}
				if (value.constraints != null) {
					errors = errors.concat(PrivateValidators.inlineVolumeConstraint_3b1f04aede5a1e865648311c626a62e2(value.constraints, path.concat(['constraints'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "size": 1,
		 *   "name": "some value",
		 *   "provider": "some value",
		 *   "options": {},
		 *   "shared": false
		 * }
		 */
		ExternalVolumeInfo: function(value, path) {
			var errors = [];
			path = path || [];
			errors = errors.concat([
				Validators.DVDIExternalVolumeInfo(value, path),
				Validators.CSIExternalVolumeInfo(value, path),
			].sort(function(a, b) {
				return a.length - b.length;
			})[0]);
			return errors;
		},
	
		/**
		 * "some value"
		 */
		Name: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[5].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^[a-z0-9]([-a-z0-9]*[a-z0-9])?$"}));
				}
				if (value.length > 63) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":63}));
				}
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * true
		 */
		BooleanType: function(value, path) {
			var errors = [];
			path = path || [];
			if ((value !== false) && (value !== true)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_BOOLEAN"));
			} else {
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		InstanceId: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[6].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^(.+)\\.(instance-|marathon-)([^\\.]+)$"}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		IpProtocol: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[6].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"ipv4, ipv6"}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		PersistenceId: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[7].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^([^#]+)[#]([^#]+)[#]([^#]+)$"}));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 */
		NumberType: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		PersistentVolumeType: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[13].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"root, path, mount"}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "size": 1,
		 *   "name": "some value",
		 *   "provider": "some value",
		 *   "options": {},
		 *   "shared": false
		 * }
		 */
		DVDIExternalVolumeInfo: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.size != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.size, path.concat(['size'])));
				}
				if (value.name != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.name, path.concat(['name'])));
				}
				if (value.provider != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.provider, path.concat(['provider'])));
				}
				if (value.options != null) {
					errors = errors.concat(Validators.KVLabels(value.options, path.concat(['options'])));
				}
				if (value.shared != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.shared, path.concat(['shared'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "provider": "some value",
		 *   "options": {
		 *     "pluginName": "some value",
		 *     "capability": {
		 *       "accessMode": "some value",
		 *       "accessType": "some value",
		 *       "fsType": "some value",
		 *       "mountFlags": [
		 *         "some value"
		 *       ]
		 *     },
		 *     "nodeStageSecret": {},
		 *     "nodePublishSecret": {},
		 *     "volumeContext": {}
		 *   }
		 * }
		 */
		CSIExternalVolumeInfo: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.name, path.concat(['name'])));
				}
				if (value.provider == null) {
					errors.push(new RAMLError(path.concat(['provider']), context, "PROP_IS_MISSING", {name: 'provider'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_c595ffe8648c9df09d9dcb41c8f7e8d8(value.provider, path.concat(['provider'])));
				}
				if (value.options == null) {
					errors.push(new RAMLError(path.concat(['options']), context, "PROP_IS_MISSING", {name: 'options'}));
				} else {
					errors = errors.concat(Validators.CSIExternalVolumeInfoOptions(value.options, path.concat(['options'])));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   "some value"
		 * ]
		 */
		VolumeConstraint: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.StringType(value, path.concat([i]))
					);
				}, errors);
				if (value.length < 2) {
					errors.push(new RAMLError(path, context, "ITEMS_MIN", {"value":2}));
				}
				if (value.length > 3) {
					errors.push(new RAMLError(path, context, "ITEMS_MAX", {"value":3}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "pluginName": "some value",
		 *   "capability": {
		 *     "accessMode": "some value",
		 *     "accessType": "some value",
		 *     "fsType": "some value",
		 *     "mountFlags": [
		 *       "some value"
		 *     ]
		 *   },
		 *   "nodeStageSecret": {},
		 *   "nodePublishSecret": {},
		 *   "volumeContext": {}
		 * }
		 */
		CSIExternalVolumeInfoOptions: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.pluginName == null) {
					errors.push(new RAMLError(path.concat(['pluginName']), context, "PROP_IS_MISSING", {name: 'pluginName'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.pluginName, path.concat(['pluginName'])));
				}
				if (value.capability == null) {
					errors.push(new RAMLError(path.concat(['capability']), context, "PROP_IS_MISSING", {name: 'capability'}));
				} else {
					errors = errors.concat(Validators.CSICapability(value.capability, path.concat(['capability'])));
				}
				if (value.nodeStageSecret != null) {
					errors = errors.concat(Validators.KVLabels(value.nodeStageSecret, path.concat(['nodeStageSecret'])));
				}
				if (value.nodePublishSecret != null) {
					errors = errors.concat(Validators.KVLabels(value.nodePublishSecret, path.concat(['nodePublishSecret'])));
				}
				if (value.volumeContext != null) {
					errors = errors.concat(Validators.KVLabels(value.volumeContext, path.concat(['volumeContext'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "accessMode": "some value",
		 *   "accessType": "some value",
		 *   "fsType": "some value",
		 *   "mountFlags": [
		 *     "some value"
		 *   ]
		 * }
		 */
		CSICapability: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.accessMode == null) {
					errors.push(new RAMLError(path.concat(['accessMode']), context, "PROP_IS_MISSING", {name: 'accessMode'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d7a22895ae8ee33fd7c2a61469e79c18(value.accessMode, path.concat(['accessMode'])));
				}
				if (value.accessType == null) {
					errors.push(new RAMLError(path.concat(['accessType']), context, "PROP_IS_MISSING", {name: 'accessType'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_e29b30fec57d24708b46494da69c169a(value.accessType, path.concat(['accessType'])));
				}
				if (value.fsType != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.fsType, path.concat(['fsType'])));
				}
				if (value.mountFlags != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_c16b318f4fe8b85b2a89ed06127bb779(value.mountFlags, path.concat(['mountFlags'])));
				}
			}
			return errors;
		},
	
	};
	
	// Expose validator functions, bound to local overrides
	Object.keys(Validators).forEach((function(key) {
		this[key] = Validators[key];
	}).bind(this));
	
	// Expose .clone function that allows further overrides to apply
	this.clone = function(cloneConfig) {
		return new RAMLValidator(Object.assign(config, cloneConfig));
	}
}

return new RAMLValidator();
})();