export const ACL_GROUPS_CHANGE = "ACL_GROUPS_CHANGE";
export const ACL_GROUPS_REQUEST_ERROR = "ACL_GROUPS_REQUEST_ERROR";
export const ACL_GROUP_SET_GROUPS_FETCHING = "ACL_GROUP_SET_GROUPS_FETCHING";
export const ACL_GROUP_SET_GROUPS = "ACL_GROUP_SET_GROUPS";
export const ACL_GROUP_ADD_USER_ERROR = "ACL_GROUP_ADD_USER_ERROR";
export const ACL_GROUP_ADD_USER_SUCCESS = "ACL_GROUP_ADD_USER_SUCCESS";
export const ACL_GROUP_CREATE_ERROR = "ACL_GROUP_CREATE_ERROR";
export const ACL_GROUP_CREATE_SUCCESS = "ACL_GROUP_CREATE_SUCCESS";
export const ACL_LDAP_GROUP_CREATE_ERROR = "ACL_LDAP_GROUP_CREATE_ERROR";
export const ACL_LDAP_GROUP_CREATE_SUCCESS = "ACL_LDAP_GROUP_CREATE_SUCCESS";
export const ACL_LDAP_GROUP_CREATE_PARTIAL_SUCCESS =
  "ACL_LDAP_GROUP_CREATE_PARTIAL_SUCCESS";
export const ACL_GROUP_DELETE_ERROR = "ACL_GROUP_DELETE_ERROR";
export const ACL_GROUP_DELETE_SUCCESS = "ACL_GROUP_DELETE_SUCCESS";
export const ACL_GROUP_DETAILS_FETCHED_ERROR =
  "ACL_GROUP_DETAILS_FETCHED_ERROR";
export const ACL_GROUP_DETAILS_FETCHED_SUCCESS =
  "ACL_GROUP_DETAILS_FETCHED_SUCCESS";
export const ACL_GROUP_DETAILS_GROUP_CHANGE = "ACL_GROUP_DETAILS_GROUP_CHANGE";
export const ACL_GROUP_DETAILS_GROUP_ERROR = "ACL_GROUP_DETAILS_GROUP_ERROR";
export const ACL_GROUP_DETAILS_PERMISSIONS_CHANGE =
  "ACL_GROUP_DETAILS_PERMISSIONS_CHANGE";
export const ACL_GROUP_DETAILS_PERMISSIONS_ERROR =
  "ACL_GROUP_DETAILS_PERMISSIONS_ERROR";
export const ACL_GROUP_DETAILS_SERVICE_ACCOUNTS_CHANGE =
  "ACL_GROUP_DETAILS_SERVICE_ACCOUNTS_CHANGE";
export const ACL_GROUP_DETAILS_SERVICE_ACCOUNTS_ERROR =
  "ACL_GROUP_DETAILS_SERVICE_ACCOUNTS_ERROR";
export const ACL_GROUP_DETAILS_USERS_CHANGE = "ACL_GROUP_DETAILS_USERS_CHANGE";
export const ACL_GROUP_DETAILS_USERS_ERROR = "ACL_GROUP_DETAILS_USERS_ERROR";
export const ACL_GROUP_REMOVE_USER_SUCCESS = "ACL_GROUP_REMOVE_USER_SUCCESS";
export const ACL_GROUP_REMOVE_USER_ERROR = "ACL_GROUP_REMOVE_USER_ERROR";
export const ACL_GROUP_UPDATE_ERROR = "ACL_GROUP_UPDATE_ERROR";
export const ACL_GROUP_UPDATE_SUCCESS = "ACL_GROUP_UPDATE_SUCCESS";
export const ACL_GROUP_USERS_CHANGED = "ACL_GROUP_USERS_CHANGED";
