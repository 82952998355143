export const REQUEST_PROVIDER_CALLBACK_URL_SUCCESS =
  "REQUEST_PROVIDER_CALLBACK_URL_SUCCESS";
export const REQUEST_PROVIDER_CALLBACK_URL_ERROR =
  "REQUEST_PROVIDER_CALLBACK_URL_ERROR";
export const REQUEST_PROVIDER_CREATE_SUCCESS =
  "REQUEST_PROVIDER_CREATE_SUCCESS";
export const REQUEST_PROVIDER_CREATE_ERROR = "REQUEST_PROVIDER_CREATE_ERROR";
export const REQUEST_PROVIDER_DELETE_SUCCESS =
  "REQUEST_PROVIDER_DELETE_SUCCESS";
export const REQUEST_PROVIDER_DELETE_ERROR = "REQUEST_PROVIDER_DELETE_ERROR";
export const REQUEST_PROVIDER_SUCCESS = "REQUEST_PROVIDER_SUCCESS";
export const REQUEST_PROVIDER_ERROR = "REQUEST_PROVIDER_ERROR";
export const REQUEST_PROVIDERS_SUCCESS = "REQUEST_PROVIDERS_SUCCESS";
export const REQUEST_PROVIDERS_ERROR = "REQUEST_PROVIDERS_ERROR";
export const REQUEST_PROVIDER_UPDATE_SUCCESS =
  "REQUEST_PROVIDER_UPDATE_SUCCESS";
export const REQUEST_PROVIDER_UPDATE_ERROR = "REQUEST_PROVIDER_UPDATE_ERROR";
