export const ACL_CREATE_ERROR = "ACL_CREATE_ERROR";
export const ACL_CREATE_SUCCESS = "ACL_CREATE_SUCCESS";
export const ACL_GROUP_GRANT_ACTION_CHANGE = "ACL_GROUP_GRANT_ACTION_CHANGE";
export const ACL_GROUP_GRANT_ACTION_ERROR = "ACL_GROUP_GRANT_ACTION_ERROR";
export const ACL_GROUP_REVOKE_ACTION_CHANGE = "ACL_GROUP_REVOKE_ACTION_CHANGE";
export const ACL_GROUP_REVOKE_ACTION_ERROR = "ACL_GROUP_REVOKE_ACTION_ERROR";
export const ACL_RESOURCE_ACLS_CHANGE = "ACL_RESOURCE_ACLS_CHANGE";
export const ACL_RESOURCE_ACLS_ERROR = "ACL_RESOURCE_ACLS_ERROR";
export const ACL_SCHEMA_CHANGE = "ACL_SCHEMA_CHANGE";
export const ACL_SCHEMA_ERROR = "ACL_SCHEMA_ERROR";
export const ACL_USER_GRANT_ACTION_CHANGE = "ACL_USER_GRANT_ACTION_CHANGE";
export const ACL_USER_GRANT_ACTION_ERROR = "ACL_USER_GRANT_ACTION_ERROR";
export const ACL_USER_REVOKE_ACTION_CHANGE = "ACL_USER_REVOKE_ACTION_CHANGE";
export const ACL_USER_REVOKE_ACTION_ERROR = "ACL_USER_REVOKE_ACTION_ERROR";
