export const ACL_SERVICE_ACCOUNT_CREATE_ERROR =
  "ACL_SERVICE_ACCOUNT_CREATE_ERROR";
export const ACL_SERVICE_ACCOUNT_CREATE_SUCCESS =
  "ACL_SERVICE_ACCOUNT_CREATE_SUCCESS";
export const ACL_SERVICE_ACCOUNT_DELETE_ERROR =
  "ACL_SERVICE_ACCOUNT_DELETE_ERROR";
export const ACL_SERVICE_ACCOUNT_DELETE_SUCCESS =
  "ACL_SERVICE_ACCOUNT_DELETE_SUCCESS";
export const ACL_SERVICE_ACCOUNT_DETAILS_FETCH_START =
  "ACL_SERVICE_ACCOUNT_DETAILS_FETCH_START";
export const ACL_SERVICE_ACCOUNT_DETAILS_FETCHED_ERROR =
  "ACL_SERVICE_ACCOUNT_DETAILS_FETCHED_ERROR";
export const ACL_SERVICE_ACCOUNT_DETAILS_FETCHED_SUCCESS =
  "ACL_SERVICE_ACCOUNT_DETAILS_FETCHED_SUCCESS";
export const ACL_SERVICE_ACCOUNT_DETAILS_GROUPS_CHANGE =
  "ACL_SERVICE_ACCOUNT_DETAILS_GROUPS_CHANGE";
export const ACL_SERVICE_ACCOUNT_DETAILS_GROUPS_ERROR =
  "ACL_SERVICE_ACCOUNT_DETAILS_GROUPS_ERROR";
export const ACL_SERVICE_ACCOUNT_DETAILS_PERMISSIONS_CHANGE =
  "ACL_SERVICE_ACCOUNT_DETAILS_PERMISSIONS_CHANGE";
export const ACL_SERVICE_ACCOUNT_DETAILS_PERMISSIONS_ERROR =
  "ACL_SERVICE_ACCOUNT_DETAILS_PERMISSIONS_ERROR";
export const ACL_SERVICE_ACCOUNT_DETAILS_SERVICE_ACCOUNT_CHANGE =
  "ACL_SERVICE_ACCOUNT_DETAILS_SERVICE_ACCOUNT_CHANGE";
export const ACL_SERVICE_ACCOUNT_DETAILS_SERVICE_ACCOUNT_ERROR =
  "ACL_SERVICE_ACCOUNT_DETAILS_SERVICE_ACCOUNT_ERROR";
export const ACL_SERVICE_ACCOUNT_SET_SERVICE_ACCOUNT =
  "ACL_SERVICE_ACCOUNT_SET_SERVICE_ACCOUNT";
export const ACL_SERVICE_ACCOUNT_UPDATE_ERROR =
  "ACL_SERVICE_ACCOUNT_UPDATE_ERROR";
export const ACL_SERVICE_ACCOUNT_UPDATE_SUCCESS =
  "ACL_SERVICE_ACCOUNT_UPDATE_SUCCESS";
export const ACL_SERVICE_ACCOUNTS_CHANGE = "ACL_SERVICE_ACCOUNTS_CHANGE";
export const ACL_SERVICE_ACCOUNTS_ERROR = "ACL_SERVICE_ACCOUNTS_ERROR";
