export const REQUEST_ACL_GROUPS_ERROR = "REQUEST_ACL_GROUPS_ERROR";
export const REQUEST_ACL_GROUPS_SUCCESS = "REQUEST_ACL_GROUPS_SUCCESS";
export const REQUEST_ACL_GROUP_ADD_USER_ERROR =
  "REQUEST_ACL_GROUP_ADD_USER_ERROR";
export const REQUEST_ACL_GROUP_ADD_USER_SUCCESS =
  "REQUEST_ACL_GROUP_ADD_USER_SUCCESS";
export const REQUEST_ACL_GROUP_CREATE_ERROR = "REQUEST_ACL_GROUP_CREATE_ERROR";
export const REQUEST_ACL_GROUP_CREATE_SUCCESS =
  "REQUEST_ACL_GROUP_CREATE_SUCCESS";
export const REQUEST_ACL_LDAP_GROUP_CREATE_ERROR =
  "REQUEST_ACL_LDAP_GROUP_CREATE_ERROR";
export const REQUEST_ACL_LDAP_GROUP_CREATE_PARTIAL_SUCCESS =
  "REQUEST_ACL_LDAP_GROUP_CREATE_PARTIAL_SUCCESS";
export const REQUEST_ACL_LDAP_GROUP_CREATE_SUCCESS =
  "REQUEST_ACL_LDAP_GROUP_CREATE_SUCCESS";
export const REQUEST_ACL_GROUP_DELETE_ERROR = "REQUEST_ACL_GROUP_DELETE_ERROR";
export const REQUEST_ACL_GROUP_DELETE_SUCCESS =
  "REQUEST_ACL_GROUP_DELETE_SUCCESS";
export const REQUEST_ACL_GROUP_DETAILS_ERROR =
  "REQUEST_ACL_GROUP_DETAILS_ERROR";
export const REQUEST_ACL_GROUP_DETAILS_SUCCESS =
  "REQUEST_ACL_GROUP_DETAILS_SUCCESS";
export const REQUEST_ACL_GROUP_ERROR = "REQUEST_ACL_GROUP_ERROR";
export const REQUEST_ACL_GROUP_PERMISSIONS_ERROR =
  "REQUEST_ACL_GROUP_PERMISSIONS_ERROR";
export const REQUEST_ACL_GROUP_PERMISSIONS_SUCCESS =
  "REQUEST_ACL_GROUP_PERMISSIONS_SUCCESS";
export const REQUEST_ACL_GROUP_REMOVE_USER_ERROR =
  "REQUEST_ACL_GROUP_REMOVE_USER_ERROR";
export const REQUEST_ACL_GROUP_REMOVE_USER_SUCCESS =
  "REQUEST_ACL_GROUP_REMOVE_USER_SUCCESS";
export const REQUEST_ACL_GROUP_SERVICE_ACCOUNTS_ERROR =
  "REQUEST_ACL_GROUP_SERVICE_ACCOUNTS_ERROR";
export const REQUEST_ACL_GROUP_SERVICE_ACCOUNTS_SUCCESS =
  "REQUEST_ACL_GROUP_SERVICE_ACCOUNTS_SUCCESS";
export const REQUEST_ACL_GROUP_SUCCESS = "REQUEST_ACL_GROUP_SUCCESS";
export const REQUEST_ACL_GROUP_UPDATE_ERROR = "REQUEST_ACL_GROUP_UPDATE_ERROR";
export const REQUEST_ACL_GROUP_UPDATE_SUCCESS =
  "REQUEST_ACL_GROUP_UPDATE_SUCCESS";
export const REQUEST_ACL_GROUP_USERS_ERROR = "REQUEST_ACL_GROUP_USERS_ERROR";
export const REQUEST_ACL_GROUP_USERS_SUCCESS =
  "REQUEST_ACL_GROUP_USERS_SUCCESS";
