export const REQUEST_NETWORKING_BACKEND_CONNECTIONS_ERROR =
  "REQUEST_NETWORKING_BACKEND_CONNECTIONS_ERROR";
export const REQUEST_NETWORKING_BACKEND_CONNECTIONS_SUCCESS =
  "REQUEST_NETWORKING_BACKEND_CONNECTIONS_SUCCESS";
export const REQUEST_NETWORKING_BACKEND_CONNECTIONS_ONGOING =
  "REQUEST_NETWORKING_BACKEND_CONNECTIONS_ONGOING";
export const REQUEST_NETWORKING_NODE_MEMBERSHIPS_ERROR =
  "REQUEST_NETWORKING_NODE_MEMBERSHIPS_ERROR";
export const REQUEST_NETWORKING_NODE_MEMBERSHIPS_SUCCESS =
  "REQUEST_NETWORKING_NODE_MEMBERSHIPS_SUCCESS";
export const REQUEST_NETWORKING_NODE_MEMBERSHIPS_ONGOING =
  "REQUEST_NETWORKING_NODE_MEMBERSHIPS_ONGOING";
export const REQUEST_NETWORKING_VIP_DETAIL_ERROR =
  "REQUEST_NETWORKING_VIP_DETAIL_ERROR";
export const REQUEST_NETWORKING_VIP_DETAIL_SUCCESS =
  "REQUEST_NETWORKING_VIP_DETAIL_SUCCESS";
export const REQUEST_NETWORKING_VIP_DETAIL_ONGOING =
  "REQUEST_NETWORKING_VIP_DETAIL_ONGOING";
export const REQUEST_NETWORKING_VIP_SUMMARIES_ERROR =
  "REQUEST_NETWORKING_VIP_SUMMARIES_ERROR";
export const REQUEST_NETWORKING_VIP_SUMMARIES_SUCCESS =
  "REQUEST_NETWORKING_VIP_SUMMARIES_SUCCESS";
export const REQUEST_NETWORKING_VIP_SUMMARIES_ONGOING =
  "REQUEST_NETWORKING_VIP_SUMMARIES_ONGOING";
export const REQUEST_NETWORKING_VIPS_ERROR = "REQUEST_NETWORKING_VIPS_ERROR";
export const REQUEST_NETWORKING_VIPS_SUCCESS =
  "REQUEST_NETWORKING_VIPS_SUCCESS";
