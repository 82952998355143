export const REQUEST_ALL_STORES_ERROR = "REQUEST_ALL_STORES_ERROR";
export const REQUEST_ALL_STORES_SUCCESS = "REQUEST_ALL_STORES_SUCCESS";
export const REQUEST_STORE_BACKEND_CREATE_ERROR =
  "REQUEST_STORE_BACKEND_CREATE_ERROR";
export const REQUEST_STORE_BACKEND_CREATE_SUCCESS =
  "REQUEST_STORE_BACKEND_CREATE_SUCCESS";
export const REQUEST_STORE_BACKEND_DELETE_ERROR =
  "REQUEST_STORE_BACKEND_DELETE_ERROR";
export const REQUEST_STORE_BACKEND_DELETE_SUCCESS =
  "REQUEST_STORE_BACKEND_DELETE_SUCCESS";
export const REQUEST_STORE_BACKEND_ERROR = "REQUEST_STORE_BACKEND_ERROR";
export const REQUEST_STORE_BACKEND_SUCCESS = "REQUEST_STORE_BACKEND_SUCCESS";
export const REQUEST_STORE_CREATE_SECRET_ERROR =
  "REQUEST_STORE_CREATE_SECRET_ERROR";
export const REQUEST_STORE_CREATE_SECRET_SUCCESS =
  "REQUEST_STORE_CREATE_SECRET_SUCCESS";
export const REQUEST_STORE_DELETE_SECRET_ERROR =
  "REQUEST_STORE_DELETE_SECRET_ERROR";
export const REQUEST_STORE_DELETE_SECRET_SUCCESS =
  "REQUEST_STORE_DELETE_SECRET_SUCCESS";
export const REQUEST_STORE_INITIALIZATION_ERROR =
  "REQUEST_STORE_INITIALIZATION_ERROR";
export const REQUEST_STORE_INITIALIZATION_STATUS_ERROR =
  "REQUEST_STORE_INITIALIZATION_STATUS_ERROR";
export const REQUEST_STORE_INITIALIZATION_STATUS_SUCCESS =
  "REQUEST_STORE_INITIALIZATION_STATUS_SUCCESS";
export const REQUEST_STORE_INITIALIZATION_SUCCESS =
  "REQUEST_STORE_INITIALIZATION_SUCCESS";
export const REQUEST_STORE_RENEW_SECRET_ERROR =
  "REQUEST_STORE_RENEW_SECRET_ERROR";
export const REQUEST_STORE_RENEW_SECRET_SUCCESS =
  "REQUEST_STORE_RENEW_SECRET_SUCCESS";
export const REQUEST_STORE_REVOKE_SECRET_ERROR =
  "REQUEST_STORE_REVOKE_SECRET_ERROR";
export const REQUEST_STORE_REVOKE_SECRET_SUCCESS =
  "REQUEST_STORE_REVOKE_SECRET_SUCCESS";
export const REQUEST_STORE_SEAL_STATUS_ERROR =
  "REQUEST_STORE_SEAL_STATUS_ERROR";
export const REQUEST_STORE_SEAL_STATUS_SUCCESS =
  "REQUEST_STORE_SEAL_STATUS_SUCCESS";
export const REQUEST_STORE_SECRETS_ERROR = "REQUEST_STORE_SECRETS_ERROR";
export const REQUEST_STORE_SECRETS_SUCCESS = "REQUEST_STORE_SECRETS_SUCCESS";
export const REQUEST_STORE_SECRET_ERROR = "REQUEST_STORE_SECRET_ERROR";
export const REQUEST_STORE_SECRET_SUCCESS = "REQUEST_STORE_SECRET_SUCCESS";
export const REQUEST_STORE_UNSEAL_ERROR = "REQUEST_STORE_UNSEAL_ERROR";
export const REQUEST_STORE_UNSEAL_SUCCESS = "REQUEST_STORE_UNSEAL_SUCCESS";
export const REQUEST_STORE_UPDATE_SECRET_ERROR =
  "REQUEST_STORE_UPDATE_SECRET_ERROR";
export const REQUEST_STORE_UPDATE_SECRET_SUCCESS =
  "REQUEST_STORE_UPDATE_SECRET_SUCCESS";
