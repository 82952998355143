export const NETWORKING_BACKEND_CONNECTIONS_CHANGE =
  "NETWORKING_BACKEND_CONNECTIONS_CHANGE";
export const NETWORKING_BACKEND_CONNECTIONS_REQUEST_ERROR =
  "NETWORKING_BACKEND_CONNECTIONS_REQUEST_ERROR";
export const NETWORKING_NODE_MEMBERSHIP_CHANGE =
  "NETWORKING_NODE_MEMBERSHIP_CHANGE";
export const NETWORKING_NODE_MEMBERSHIP_REQUEST_ERROR =
  "NETWORKING_NODE_MEMBERSHIP_REQUEST_ERROR";
export const NETWORKING_VIP_DETAIL_CHANGE = "NETWORKING_VIP_DETAIL_CHANGE";
export const NETWORKING_VIP_DETAIL_REQUEST_ERROR =
  "NETWORKING_VIP_DETAIL_REQUEST_ERROR";
export const NETWORKING_VIP_SUMMARIES_CHANGE =
  "NETWORKING_VIP_SUMMARIES_CHANGE";
export const NETWORKING_VIP_SUMMARIES_ERROR = "NETWORKING_VIP_SUMMARIES_ERROR";
export const NETWORKING_VIPS_CHANGE = "NETWORKING_VIPS_CHANGE";
export const NETWORKING_VIPS_REQUEST_ERROR = "NETWORKING_VIPS_REQUEST_ERROR";
