export const REQUEST_ACL_DIRECTORIES_SUCCESS =
  "REQUEST_ACL_DIRECTORIES_SUCCESS";
export const REQUEST_ACL_DIRECTORIES_ERROR = "REQUEST_ACL_DIRECTORIES_ERROR";
export const REQUEST_ACL_DIRECTORY_ADD_SUCCESS =
  "REQUEST_ACL_DIRECTORY_ADD_SUCCESS";
export const REQUEST_ACL_DIRECTORY_ADD_ERROR =
  "REQUEST_ACL_DIRECTORY_ADD_ERROR";
export const REQUEST_ACL_DIRECTORY_DELETE_SUCCESS =
  "REQUEST_ACL_DIRECTORY_DELETE_SUCCESS";
export const REQUEST_ACL_DIRECTORY_DELETE_ERROR =
  "REQUEST_ACL_DIRECTORY_DELETE_ERROR";
export const REQUEST_ACL_DIRECTORY_TEST_SUCCESS =
  "REQUEST_ACL_DIRECTORY_TEST_SUCCESS";
export const REQUEST_ACL_DIRECTORY_TEST_ERROR =
  "REQUEST_ACL_DIRECTORY_TEST_ERROR";
export const REQUEST_ACL_DIRECTORY_TEST_ONGOING =
  "REQUEST_ACL_DIRECTORY_TEST_ONGOING";
