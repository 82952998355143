export default {
  type: {
    host: "HOST",
    ephemeral: "EPHEMERAL",
    localPersistent: "PERSISTENT",
    dss: "DSS",
    unknown: "UNKNOWN",
    external: "EXTERNAL",
  },
  mode: {
    rw: "RW",
    ro: "RO",
  },
};
