let SDK;

export default {
  getSDK() {
    return SDK;
  },
  setSDK(pluginSDK) {
    SDK = pluginSDK;
  },
};
