export const PROVIDER_CALLBACK_URL_SUCCESS = "PROVIDER_CALLBACK_URL_SUCCESS";
export const PROVIDER_CALLBACK_URL_ERROR = "PROVIDER_CALLBACK_URL_ERROR";
export const PROVIDER_CREATE_SUCCESS = "PROVIDER_CREATE_SUCCESS";
export const PROVIDER_CREATE_ERROR = "PROVIDER_CREATE_ERROR";
export const PROVIDER_DELETE_SUCCESS = "PROVIDER_DELETE_SUCCESS";
export const PROVIDER_DELETE_ERROR = "PROVIDER_DELETE_ERROR";
export const PROVIDER_SUCCESS = "PROVIDER_SUCCESS";
export const PROVIDER_ERROR = "PROVIDER_ERROR";
export const PROVIDERS_CHANGE = "PROVIDERS_CHANGE";
export const PROVIDERS_ERRORS = "PROVIDERS_ERRORS";
export const PROVIDER_UPDATE_SUCCESS = "PROVIDER_UPDATE_SUCCESS";
export const PROVIDER_UPDATE_ERROR = "PROVIDER_UPDATE_ERROR";
