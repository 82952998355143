export const APP_STORE_CHANGE = "APP_STORE_CHANGE";
export const AUTH_USER_LOGIN_ERROR = "AUTH_USER_LOGIN_ERROR";
export const AUTH_USER_LOGIN_CHANGED = "AUTH_USER_LOGIN_CHANGED";
export const AUTH_USER_LOGOUT_ERROR = "AUTH_USER_LOGOUT_ERROR";
export const AUTH_USER_LOGOUT_SUCCESS = "AUTH_USER_LOGOUT_SUCCESS";
export const CLUSTER_CCID_SUCCESS = "CLUSTER_CCID_SUCCESS";
export const CLUSTER_CCID_ERROR = "CLUSTER_CCID_ERROR";
export const CONFIG_ERROR = "CONFIG_ERROR";
export const CONFIG_LOADED = "CONFIG_LOADED";
export const COSMOS_PACKAGE_DESCRIBE_CHANGE = "COSMOS_PACKAGE_DESCRIBE_CHANGE";
export const COSMOS_PACKAGE_DESCRIBE_ERROR = "COSMOS_PACKAGE_DESCRIBE_ERROR";
export const COSMOS_SERVICE_DESCRIBE_CHANGE = "COSMOS_SERVICE_DESCRIBE_CHANGE";
export const COSMOS_SERVICE_DESCRIBE_ERROR = "COSMOS_SERVICE_DESCRIBE_ERROR";
export const COSMOS_SERVICE_UPDATE_SUCCESS = "COSMOS_SERVICE_UPDATE_SUCCESS";
export const COSMOS_SERVICE_UPDATE_ERROR = "COSMOS_SERVICE_UPDATE_ERROR";
export const COSMOS_LIST_VERSIONS_CHANGE = "COSMOS_LIST_VERSIONS_CHANGE";
export const COSMOS_LIST_VERSIONS_ERROR = "COSMOS_LIST_VERSIONS_ERROR";
export const COSMOS_INSTALL_ERROR = "COSMOS_INSTALL_ERROR";
export const COSMOS_INSTALL_SUCCESS = "COSMOS_INSTALL_SUCCESS";
export const COSMOS_LIST_CHANGE = "COSMOS_LIST_CHANGE";
export const COSMOS_LIST_ERROR = "COSMOS_LIST_ERROR";
export const COSMOS_REPOSITORIES_ERROR = "COSMOS_REPOSITORIES_ERROR";
export const COSMOS_REPOSITORIES_SUCCESS = "COSMOS_REPOSITORIES_SUCCESS";
export const COSMOS_REPOSITORY_ADD_ERROR = "COSMOS_REPOSITORY_ADD_ERROR";
export const COSMOS_REPOSITORY_ADD_SUCCESS = "COSMOS_REPOSITORY_ADD_SUCCESS";
export const COSMOS_REPOSITORY_DELETE_ERROR = "COSMOS_REPOSITORY_DELETE_ERROR";
export const COSMOS_REPOSITORY_DELETE_SUCCESS =
  "COSMOS_REPOSITORY_DELETE_SUCCESS";
export const COSMOS_SEARCH_CHANGE = "COSMOS_SEARCH_CHANGE";
export const COSMOS_SEARCH_ERROR = "COSMOS_SEARCH_ERROR";
export const COSMOS_UNINSTALL_ERROR = "COSMOS_UNINSTALL_ERROR";
export const COSMOS_UNINSTALL_SUCCESS = "COSMOS_UNINSTALL_SUCCESS";
export const DCOS_CHANGE = "DCOS_CHANGE";
export const DCOS_BUILD_INFO_CHANGE = "DCOS_BUILD_INFO_CHANGE";
export const DCOS_BUILD_INFO_ERROR = "DCOS_BUILD_INFO_ERROR";
export const HEALTH_NODE_ERROR = "HEALTH_NODE_ERROR";
export const HEALTH_NODE_SUCCESS = "HEALTH_NODE_SUCCESS";
export const HEALTH_NODE_UNIT_ERROR = "HEALTH_NODE_UNIT_ERROR";
export const HEALTH_NODE_UNIT_SUCCESS = "HEALTH_NODE_UNIT_SUCCESS";
export const HEALTH_NODE_UNITS_ERROR = "HEALTH_NODE_UNITS_ERROR";
export const HEALTH_NODE_UNITS_SUCCESS = "HEALTH_NODE_UNITS_SUCCESS";
export const HEALTH_NODES_CHANGE = "HEALTH_NODES_CHANGE";
export const HEALTH_NODES_ERROR = "HEALTH_NODES_ERROR";
export const HEALTH_UNIT_ERROR = "HEALTH_UNIT_ERROR";
export const HEALTH_UNIT_NODE_ERROR = "HEALTH_UNIT_NODE_ERROR";
export const HEALTH_UNIT_NODE_SUCCESS = "HEALTH_UNIT_NODE_SUCCESS";
export const HEALTH_UNIT_NODES_ERROR = "HEALTH_UNIT_NODES_ERROR";
export const HEALTH_UNIT_NODES_SUCCESS = "HEALTH_UNIT_NODES_SUCCESS";
export const HEALTH_UNIT_SUCCESS = "HEALTH_UNIT_SUCCESS";
export const HEALTH_UNITS_CHANGE = "HEALTH_UNITS_CHANGE";
export const HEALTH_UNITS_ERROR = "HEALTH_UNITS_ERROR";
export const LANGUAGE_MODAL_CHANGE = "LANGUAGE_MODAL_CHANGE";
export const LANGUAGE_MODAL_CLOSE = "LANGUAGE_MODAL_CLOSE";
export const MESOS_STATE_CHANGE = "MESOS_STATE_CHANGE";
export const MESOS_STATE_REQUEST_ERROR = "MESOS_STATE_REQUEST_ERROR";
export const MESOS_SUMMARY_CHANGE = "MESOS_SUMMARY_CHANGE";
export const MESOS_SUMMARY_REQUEST_ERROR = "MESOS_SUMMARY_REQUEST_ERROR";
export const METADATA_CHANGE = "METADATA_CHANGE";
export const NOTIFICATION_CHANGE = "NOTIFICATION_CHANGE";
export const SHOW_CLI_INSTRUCTIONS = "SHOW_CLI_INSTRUCTIONS";
export const SHOW_CLUSTER_LINKING = "SHOW_CLUSTER_LINKING";
export const SIDEBAR_CHANGE = "SIDEBAR_CHANGE";
export const SIDEBAR_WIDTH_CHANGE = "SIDEBAR_WIDTH_CHANGE";
export const SYSTEM_LOG_CHANGE = "SYSTEM_LOG_CHANGE";
export const SYSTEM_LOG_REQUEST_ERROR = "SYSTEM_LOG_REQUEST_ERROR";
export const SYSTEM_LOG_STREAM_TYPES_SUCCESS =
  "SYSTEM_LOG_STREAM_TYPES_SUCCESS";
export const SYSTEM_LOG_STREAM_TYPES_ERROR = "SYSTEM_LOG_STREAM_TYPES_ERROR";
export const USER_CREATE_ERROR = "USER_CREATE_ERROR";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_DELETE_ERROR = "USER_DELETE_ERROR";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USERS_CHANGE = "USERS_CHANGE";
export const USERS_REQUEST_ERROR = "USERS_REQUEST_ERROR";
export const VIRTUAL_NETWORKS_CHANGE = "VIRTUAL_NETWORKS_CHANGE";
export const VIRTUAL_NETWORKS_REQUEST_ERROR = "VIRTUAL_NETWORKS_REQUEST_ERROR";
