import { Trans } from "@lingui/macro";
import classNames from "classnames";
import mixin from "reactjs-mixin";
import { Modal } from "reactjs-components";
import * as React from "react";

import { Hooks } from "PluginSDK";
import StoreMixin from "#SRC/js/mixins/StoreMixin";

import ModalHeading from "./modals/ModalHeading";

function getEventsFromStoreListeners(storeListeners) {
  const events = [];

  storeListeners.forEach((store) => {
    store.events.forEach((storeEvent) => {
      events.push(this.store_getChangeFunctionName(store.name, storeEvent));
    });
  });

  return events;
}

export default class ServerErrorModal extends mixin(StoreMixin) {
  state = { isOpen: false, errors: [] };

  store_listeners = Hooks.applyFilter("serverErrorModalListeners", []);
  constructor() {
    super();

    const events = getEventsFromStoreListeners.call(this, this.store_listeners);
    events.forEach((event) => {
      this[event] = this.handleServerError;
    });
  }
  handleModalClose = () => {
    this.setState({
      isOpen: false,
      errors: [],
    });
  };
  handleServerError = (errorMessage) => {
    if (!errorMessage) {
      throw new Error("No error message defined!");
    }

    const isLocked = errorMessage && /force=true/.test(errorMessage);

    const errors = this.state.errors.concat([errorMessage]);

    this.setState({
      errors,
      isOpen: !isLocked,
    });
  };

  getFooter() {
    return (
      <div className="button-collection text-align-center flush-bottom">
        <div className="button" onClick={this.handleModalClose}>
          <Trans render="span">Close</Trans>
        </div>
      </div>
    );
  }

  render() {
    const header = (
      <ModalHeading level={5}>
        <Trans render="span">An error has occurred</Trans>
      </ModalHeading>
    );

    const { errors } = this.state;
    const lastErrorIndex = errors.length - 1;
    const errorMessages = errors.map((error, index) => {
      const errorMessageClass = classNames("text-align-center", {
        // Last error message doesn't have margin bottom.
        "flush-bottom": index === lastErrorIndex,
      });

      return (
        <p className={errorMessageClass} key={index}>
          {error}
        </p>
      );
    });
    return (
      <Modal
        modalWrapperClass="server-error-modal"
        modalClass="modal"
        onClose={this.handleModalClose}
        open={this.state.isOpen}
        showHeader={true}
        showFooter={true}
        footer={this.getFooter()}
        header={header}
      >
        <div className="pod pod-short flush-right flush-left">
          {errorMessages}
        </div>
      </Modal>
    );
  }
}
