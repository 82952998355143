export const REQUEST_ACL_SERVICE_ACCOUNT_CREATE_ERROR =
  "REQUEST_ACL_SERVICE_ACCOUNT_CREATE_ERROR";
export const REQUEST_ACL_SERVICE_ACCOUNT_CREATE_SUCCESS =
  "REQUEST_ACL_SERVICE_ACCOUNT_CREATE_SUCCESS";
export const REQUEST_ACL_SERVICE_ACCOUNT_CREATE_SECRET_ERROR =
  "REQUEST_ACL_SERVICE_ACCOUNT_CREATE_SECRET_ERROR";
export const REQUEST_ACL_SERVICE_ACCOUNT_DELETE_ERROR =
  "REQUEST_ACL_SERVICE_ACCOUNT_DELETE_ERROR";
export const REQUEST_ACL_SERVICE_ACCOUNT_DELETE_SUCCESS =
  "REQUEST_ACL_SERVICE_ACCOUNT_DELETE_SUCCESS";
export const REQUEST_ACL_SERVICE_ACCOUNT_GROUPS_ERROR =
  "REQUEST_ACL_SERVICE_ACCOUNT_GROUPS_ERROR";
export const REQUEST_ACL_SERVICE_ACCOUNT_GROUPS_SUCCESS =
  "REQUEST_ACL_SERVICE_ACCOUNT_GROUPS_SUCCESS";
export const REQUEST_ACL_SERVICE_ACCOUNT_PERMISSIONS_ERROR =
  "REQUEST_ACL_SERVICE_ACCOUNT_PERMISSIONS_ERROR";
export const REQUEST_ACL_SERVICE_ACCOUNT_PERMISSIONS_SUCCESS =
  "REQUEST_ACL_SERVICE_ACCOUNT_PERMISSIONS_SUCCESS";
export const REQUEST_ACL_SERVICE_ACCOUNT_SUCCESS =
  "REQUEST_ACL_SERVICE_ACCOUNT_SUCCESS";
export const REQUEST_ACL_SERVICE_ACCOUNT_ERROR =
  "REQUEST_ACL_SERVICE_ACCOUNT_ERROR";
export const REQUEST_ACL_SERVICE_ACCOUNT_UPDATE_ERROR =
  "REQUEST_ACL_SERVICE_ACCOUNT_UPDATE_ERROR";
export const REQUEST_ACL_SERVICE_ACCOUNT_UPDATE_SUCCESS =
  "REQUEST_ACL_SERVICE_ACCOUNT_UPDATE_SUCCESS";
export const REQUEST_ACL_SERVICE_ACCOUNTS_ERROR =
  "REQUEST_ACL_SERVICE_ACCOUNTS_ERROR";
export const REQUEST_ACL_SERVICE_ACCOUNTS_SUCCESS =
  "REQUEST_ACL_SERVICE_ACCOUNTS_SUCCESS";
