import * as React from "react";

class IconSAML extends React.Component {
  render() {
    return (
      <svg
        className="icon icon-saml"
        height="16"
        width="16"
        viewBox="0 0 16 16"
        {...this.props}
      >
        <g fill-rule="evenodd">
          <path
            d="M4.773 12.613a16.993 16.993 0 0 1-3.353.487c-.474.013-.948-.002-1.42-.047a33.087 33.087 0 0 1 2.26-7.7A23.68 23.68 0 0 1 5.113.32s-2.633 5.007-3.02 7.7c-.386 2.693.754 4.007 3.12 3.887a15.733 15.733 0 0 0 4.354-.807 27.2 27.2 0 0 1-4.794 1.513zM12.3 9.24c.77.84 1.453 1.756 2.04 2.733.237.41.448.832.633 1.267a33.087 33.087 0 0 1-7.853 1.653 23.68 23.68 0 0 1-5.787-.133s5.607-.053 8.16-.987c2.554-.933 3.174-2.586 1.947-4.586a15.733 15.733 0 0 0-2.773-3.454A27.2 27.2 0 0 1 12.3 9.24zM5.893 5.1a15.733 15.733 0 0 0-1.46 4.18A27.2 27.2 0 0 1 5.5 4.367a16.993 16.993 0 0 1 1.247-3.16C6.97.79 7.22.387 7.493 0a33.087 33.087 0 0 1 5.56 5.787A23.68 23.68 0 0 1 16 10.767S12.96 6 10.813 4.327c-2.146-1.674-3.84-1.34-4.92.773z"
            fill-rule="nonzero"
          />
        </g>
      </svg>
    );
  }
}

export default IconSAML;
