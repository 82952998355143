export const REQUEST_CLI_INSTRUCTIONS = "REQUEST_CLI_INSTRUCTIONS";
export const REQUEST_CLUSTER_LINKING = "REQUEST_CLUSTER_LINKING";
export const REQUEST_CLUSTER_CCID_ERROR = "REQUEST_CLUSTER_CCID_ERROR";
export const REQUEST_CLUSTER_CCID_SUCCESS = "REQUEST_CLUSTER_CCID_SUCCESS";
export const REQUEST_CONFIG_ERROR = "REQUEST_CONFIG_ERROR";
export const REQUEST_CONFIG_SUCCESS = "REQUEST_CONFIG_SUCCESS";
export const REQUEST_COSMOS_PACKAGE_DESCRIBE_ERROR =
  "REQUEST_COSMOS_PACKAGE_DESCRIBE_ERROR";
export const REQUEST_COSMOS_PACKAGE_DESCRIBE_SUCCESS =
  "REQUEST_COSMOS_PACKAGE_DESCRIBE_SUCCESS";
export const REQUEST_COSMOS_PACKAGE_INSTALL_ERROR =
  "REQUEST_COSMOS_PACKAGE_INSTALL_ERROR";
export const REQUEST_COSMOS_PACKAGE_INSTALL_SUCCESS =
  "REQUEST_COSMOS_PACKAGE_INSTALL_SUCCESS";
export const REQUEST_COSMOS_PACKAGE_UNINSTALL_ERROR =
  "REQUEST_COSMOS_PACKAGE_UNINSTALL_ERROR";
export const REQUEST_COSMOS_PACKAGE_UNINSTALL_SUCCESS =
  "REQUEST_COSMOS_PACKAGE_UNINSTALL_SUCCESS";
export const REQUEST_COSMOS_PACKAGE_LIST_VERSIONS_SUCCESS =
  "REQUEST_COSMOS_PACKAGE_LIST_VERSIONS_SUCCESS";
export const REQUEST_COSMOS_PACKAGE_LIST_VERSIONS_ERROR =
  "REQUEST_COSMOS_PACKAGE_LIST_VERSIONS_ERROR";
export const REQUEST_COSMOS_PACKAGES_LIST_ERROR =
  "REQUEST_COSMOS_PACKAGES_LIST_ERROR";
export const REQUEST_COSMOS_PACKAGES_LIST_SUCCESS =
  "REQUEST_COSMOS_PACKAGES_LIST_SUCCESS";
export const REQUEST_COSMOS_PACKAGES_SEARCH_ERROR =
  "REQUEST_COSMOS_PACKAGES_SEARCH_ERROR";
export const REQUEST_COSMOS_PACKAGES_SEARCH_SUCCESS =
  "REQUEST_COSMOS_PACKAGES_SEARCH_SUCCESS";
export const REQUEST_COSMOS_REPOSITORIES_LIST_ERROR =
  "REQUEST_COSMOS_REPOSITORIES_LIST_ERROR";
export const REQUEST_COSMOS_REPOSITORIES_LIST_SUCCESS =
  "REQUEST_COSMOS_REPOSITORIES_LIST_SUCCESS";
export const REQUEST_COSMOS_REPOSITORY_ADD_ERROR =
  "REQUEST_COSMOS_REPOSITORY_ADD_ERROR";
export const REQUEST_COSMOS_REPOSITORY_ADD_SUCCESS =
  "REQUEST_COSMOS_REPOSITORY_ADD_SUCCESS";
export const REQUEST_COSMOS_REPOSITORY_DELETE_ERROR =
  "REQUEST_COSMOS_REPOSITORY_DELETE_ERROR";
export const REQUEST_COSMOS_REPOSITORY_DELETE_SUCCESS =
  "REQUEST_COSMOS_REPOSITORY_DELETE_SUCCESS";
export const REQUEST_COSMOS_SERVICE_DESCRIBE_ERROR =
  "REQUEST_COSMOS_SERVICE_DESCRIBE_ERROR";
export const REQUEST_COSMOS_SERVICE_DESCRIBE_SUCCESS =
  "REQUEST_COSMOS_SERVICE_DESCRIBE_SUCCESS";
export const REQUEST_COSMOS_SERVICE_UPDATE_SUCCESS =
  "REQUEST_COSMOS_SERVICE_UPDATE_SUCCESS";
export const REQUEST_COSMOS_SERVICE_UPDATE_ERROR =
  "REQUEST_COSMOS_SERVICE_UPDATE_ERROR";
export const REQUEST_HEALTH_NODE_ERROR = "REQUEST_HEALTH_NODE_ERROR";
export const REQUEST_HEALTH_NODE_SUCCESS = "REQUEST_HEALTH_NODE_SUCCESS";
export const REQUEST_HEALTH_NODE_UNIT_ERROR = "REQUEST_HEALTH_NODE_UNIT_ERROR";
export const REQUEST_HEALTH_NODE_UNIT_SUCCESS =
  "REQUEST_HEALTH_NODE_UNIT_SUCCESS";
export const REQUEST_HEALTH_NODE_UNITS_ERROR =
  "REQUEST_HEALTH_NODE_UNITS_ERROR";
export const REQUEST_HEALTH_NODE_UNITS_SUCCESS =
  "REQUEST_HEALTH_NODE_UNITS_SUCCESS";
export const REQUEST_HEALTH_NODES_ERROR = "REQUEST_HEALTH_NODES_ERROR";
export const REQUEST_HEALTH_NODES_SUCCESS = "REQUEST_HEALTH_NODES_SUCCESS";
export const REQUEST_HEALTH_UNIT_ERROR = "REQUEST_HEALTH_UNIT_ERROR";
export const REQUEST_HEALTH_UNIT_NODE_ERROR = "REQUEST_HEALTH_UNIT_NODE_ERROR";
export const REQUEST_HEALTH_UNIT_NODE_SUCCESS =
  "REQUEST_HEALTH_UNIT_NODE_SUCCESS";
export const REQUEST_HEALTH_UNIT_NODES_ERROR =
  "REQUEST_HEALTH_UNIT_NODES_ERROR";
export const REQUEST_HEALTH_UNIT_NODES_SUCCESS =
  "REQUEST_HEALTH_UNIT_NODES_SUCCESS";
export const REQUEST_HEALTH_UNIT_SUCCESS = "REQUEST_HEALTH_UNIT_SUCCESS";
export const REQUEST_HEALTH_UNITS_ERROR = "REQUEST_HEALTH_UNITS_ERROR";
export const REQUEST_HEALTH_UNITS_SUCCESS = "REQUEST_HEALTH_UNITS_SUCCESS";
export const REQUEST_LANGUAGE_MODAL_OPEN = "REQUEST_LANGUAGE_MODAL_OPEN";
export const REQUEST_LANGUAGE_MODAL_CLOSE = "REQUEST_LANGUAGE_MODAL_CLOSE";
export const REQUEST_LANGUAGE_MODAL_CHANGE = "REQUEST_LANGUAGE_MODAL_CHANGE";
export const REQUEST_LOGIN_ERROR = "REQUEST_LOGIN_ERROR";
export const REQUEST_LOGIN_SUCCESS = "REQUEST_LOGIN_SUCCESS";
export const REQUEST_LOGOUT_ERROR = "REQUEST_LOGOUT_ERROR";
export const REQUEST_LOGOUT_SUCCESS = "REQUEST_LOGOUT_SUCCESS";
export const REQUEST_MESOS_STATE_ERROR = "REQUEST_MESOS_STATE_ERROR";
export const REQUEST_MESOS_STATE_ONGOING = "REQUEST_MESOS_STATE_ONGOING";
export const REQUEST_MESOS_STATE_SUCCESS = "REQUEST_MESOS_STATE_SUCCESS";
export const REQUEST_METADATA = "REQUEST_METADATA";
export const REQUEST_PREVIOUS_SYSTEM_LOG_ERROR =
  "REQUEST_PREVIOUS_SYSTEM_LOG_ERROR";
export const REQUEST_PREVIOUS_SYSTEM_LOG_SUCCESS =
  "REQUEST_PREVIOUS_SYSTEM_LOG_SUCCESS";
export const REQUEST_SIDEBAR_TOGGLE = "REQUEST_SIDEBAR_TOGGLE";
export const REQUEST_SIDEBAR_CLOSE = "REQUEST_SIDEBAR_CLOSE";
export const REQUEST_SIDEBAR_OPEN = "REQUEST_SIDEBAR_OPEN";
export const REQUEST_SIDEBAR_WIDTH_CHANGE = "REQUEST_SIDEBAR_WIDTH_CHANGE";
export const REQUEST_SUMMARY_ERROR = "REQUEST_SUMMARY_ERROR";
export const REQUEST_SUMMARY_ONGOING = "REQUEST_SUMMARY_ONGOING";
export const REQUEST_SUMMARY_SUCCESS = "REQUEST_SUMMARY_SUCCESS";
export const REQUEST_SYSTEM_LOG_ERROR = "REQUEST_SYSTEM_LOG_ERROR";
export const REQUEST_SYSTEM_LOG_SUCCESS = "REQUEST_SYSTEM_LOG_SUCCESS";
export const REQUEST_SYSTEM_LOG_STREAM_TYPES_ERROR =
  "REQUEST_SYSTEM_LOG_STREAM_TYPES_ERROR";
export const REQUEST_SYSTEM_LOG_STREAM_TYPES_SUCCESS =
  "REQUEST_SYSTEM_LOG_STREAM_TYPES_SUCCESS";
export const REQUEST_USER_CREATE_ERROR = "REQUEST_USER_CREATE_ERROR";
export const REQUEST_USER_CREATE_SUCCESS = "REQUEST_USER_CREATE_SUCCESS";
export const REQUEST_USER_DELETE_ERROR = "REQUEST_USER_DELETE_ERROR";
export const REQUEST_USER_DELETE_SUCCESS = "REQUEST_USER_DELETE_SUCCESS";
export const REQUEST_USERS_ERROR = "REQUEST_USERS_ERROR";
export const REQUEST_USERS_SUCCESS = "REQUEST_USERS_SUCCESS";
export const REQUEST_DCOS_BUILD_INFO_ERROR = "REQUEST_DCOS_BUILD_INFO_ERROR";
export const REQUEST_DCOS_BUILD_INFO_SUCCESS =
  "REQUEST_DCOS_BUILD_INFO_SUCCESS";
export const REQUEST_VIRTUAL_NETWORKS_ERROR = "REQUEST_VIRTUAL_NETWORKS_ERROR";
export const REQUEST_VIRTUAL_NETWORKS_SUCCESS =
  "REQUEST_VIRTUAL_NETWORKS_SUCCESS";
