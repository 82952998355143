export const REQUEST_ACL_USERS_ERROR = "REQUEST_ACL_USERS_ERROR";
export const REQUEST_ACL_USERS_SUCCESS = "REQUEST_ACL_USERS_SUCCESS";
export const REQUEST_ACL_USER_CREATE_ERROR = "REQUEST_ACL_USER_CREATE_ERROR";
export const REQUEST_ACL_USER_CREATE_SUCCESS =
  "REQUEST_ACL_USER_CREATE_SUCCESS";
export const REQUEST_ACL_LDAP_USER_CREATE_SUCCESS =
  "REQUEST_ACL_LDAP_USER_CREATE_SUCCESS";
export const REQUEST_ACL_LDAP_USER_CREATE_ERROR =
  "REQUEST_ACL_LDAP_USER_CREATE_ERROR";
export const REQUEST_ACL_USER_DELETE_ERROR = "REQUEST_ACL_USER_DELETE_ERROR";
export const REQUEST_ACL_USER_DELETE_SUCCESS =
  "REQUEST_ACL_USER_DELETE_SUCCESS";
export const REQUEST_ACL_USER_ERROR = "REQUEST_ACL_USER_ERROR";
export const REQUEST_ACL_USER_GROUPS_ERROR = "REQUEST_ACL_USER_GROUPS_ERROR";
export const REQUEST_ACL_USER_GROUPS_SUCCESS =
  "REQUEST_ACL_USER_GROUPS_SUCCESS";
export const REQUEST_ACL_USER_PERMISSIONS_ERROR =
  "REQUEST_ACL_USER_PERMISSIONS_ERROR";
export const REQUEST_ACL_USER_PERMISSIONS_SUCCESS =
  "REQUEST_ACL_USER_PERMISSIONS_SUCCESS";
export const REQUEST_ACL_USER_SUCCESS = "REQUEST_ACL_USER_SUCCESS";
export const REQUEST_ACL_USER_UPDATE_ERROR = "REQUEST_ACL_USER_UPDATE_ERROR";
export const REQUEST_ACL_USER_UPDATE_SUCCESS =
  "REQUEST_ACL_USER_UPDATE_SUCCESS";
